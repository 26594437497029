export const storageDriveForm = {
  breadcrumb: {

  },
  form: {
      titleForm: 'Vùng Lưu Trữ',
      title: 'Tiêu đề',
      key: 'Key',
      secret: 'Secret',
      region: 'Region',
      prefixBucket: 'Prefix Bucket',
      endpoint: 'Endpoint',
      actions: 'Actions',
  },
  actions: {
      close: 'Close',
      cancel: 'Cancel',
      save: 'Lưu',
      edit: 'Sửa',
      create: 'Thêm',
  },
}
