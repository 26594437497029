export const groupForm = {
    breadcrumb: {

    },
    form: {

    },
    actions: {

    },
    notifications: {
        error: {
          password: 'Your password must be between 8 - 20 characters, including uppercase letters, lowercase letters, numbers and special characters (! @ # $, etc.), but cannot contain spaces',
          passwordConfirm: 'Your password must be between 8 - 20 characters, including uppercase letters, lowercase letters, numbers and special characters (! @ # $, etc.), but cannot contain spaces',
          email: 'Email is not in correct format',
          errorCheckTab: 'An error occurred, please check the Tab information',
          packageMinio: 'This attribute cannot be left blank',
          '001.001.001': 'This attribute cannot be left blank',
        },
    },
}
