export const groupForm = {
    breadcrumb: {

    },
    form: {

    },
    actions: {
        close: 'Đóng',
        save: 'Lưu',
        edit: 'Sửa',
        block: 'Khoá',
        notBlock: 'Bỏ khoá',
        delete: 'Xoá',
        export: 'Export',
    },
    notifications: {
        error: {
          password: 'Mật khẩu của bạn phải có từ 8 - 20 kí tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt (! @ # $, V.v.), nhưng không được chứa dấu cách',
          passwordConfirm: 'Mật khẩu của bạn phải có từ 8 - 20 kí tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt (! @ # $, V.v.), nhưng không được chứa dấu cách',
          email: 'Email không đúng định dạng',
          errorCheckTab: 'Có lỗi xảy ra , vui lòng kiểm tra thông tin các Tab',
          packageMinio: 'Thuộc tính này không được bỏ trống',
          '001.001.001': 'Thuộc tính này không được bỏ trống',
        },
    },
}
