import { settingsList as settingsListVi } from './vi/settingsList.locale'
import { settingsList as settingsListEn } from './en/settingsList.locale'
export const settingsLocale = {
    vi: {
        settingsList: settingsListVi,
    },
    en: {
        settingsList: settingsListEn,
    },
}
