// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const AdminManagerRepository = Repository.get('adminManager')

const state = {
    isShowDialogDataFormEdit: false,
    dataApp: [],
    totalApp: 0,
    params: {
        // order: '',
        // orderAttribute: '',
        offset: 1,
        limit: 10,
    },
    dataHeaders: {
        appId: '',
        secretKey: '',
        appChild: '',
    },
    dataForm: {},
    dataKeyAttribute: [],
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    getters: {
        getIsShowDialogUserForm: state => {
            return state
        },
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async fetchDataListApp (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const params = context.state.params
            const dataHeaders = context.state.dataHeaders
            const dataBody = {
              headers: dataHeaders,
              params: params,
            }
            console.log('dataBody11111', dataBody)
            // call API get data list app
            const adminManagerRepository = await AdminManagerRepository.getDataListApp(params, dataHeaders, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminManagerResult = adminManagerRepository.data
            context.commit('dataApp', dataAdminManagerResult.data.list)
            context.commit('totalApp', dataAdminManagerResult.data.total)
        },
    },
    // mutations,
    // ,
}
