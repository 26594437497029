// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const AdminRoleRepository = Repository.get('adminRole')

const state = {
    // component HeaderForm
    isShowDialogRoleForm: false,
    // component Table
    isShowDialogRoleFormEdit: false,
    dataRoleAll: [],
    dataRoles: [],
    // component DialogRoleFormAdd
    dataForm: {
        id: '',
        title: '',
        level: 1,
        status: true,
    },
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    getters: {
        getIsShowDialogRoleForm: state => {
            return state
        },
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async fetchDataRoleAll(context, { isAdminLogin = false }) {
            const roles = [
                {
                    value: 'User',
                    text: 'User',
                },
                {
                    value: 'AppManager',
                    text: 'AppManager',
                },
            ]
            if (isAdminLogin) {
                roles.push(...[
                    {
                        value: 'GroupAdmin',
                        text: 'GroupAdmin',
                    },
                    {
                        value: 'Admin',
                        text: 'Admin',
                    },
                ])
            }
            context.commit('dataRoleAll', roles)
        },
        // export data role out table
        async fetchDataRoles (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const adminRolesRepository = await AdminRoleRepository.getDataRoles(tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminRoleResult = adminRolesRepository.data
            context.commit('dataRoles', dataAdminRoleResult.data.list)
        },
        setDefaultFormDataRole (context) {
            context.commit('dataForm', {
                id: '',
                title: '',
                level: 1,
                status: true,
            })
        },
        async createRole (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = context.state.dataForm
            // dataBody.level have value type number
            dataBody.level = +dataBody.level
            const adminRolesRepository = await AdminRoleRepository.createRole(dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminRoleResult = adminRolesRepository.data
            return dataAdminRoleResult
        },
        async updateRole (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            const dataBody = context.state.dataForm
            // dataBody.level have value type number
            dataBody.level = +dataBody.level
            await AdminRoleRepository.updateRole(id, dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
        },
        async deleteRole (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            await AdminRoleRepository.deleteRole(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
        },
    },
    // mutations,
    // ,
}
