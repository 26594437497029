export const appsForm = {
    breadcrumb: {
        titleAppAdd: 'Add APP',
        titleAppDetail: 'Detail APP',
        titleAppUpdate: 'Update APP',
        titleAppConfirm: 'Confirm',
    },
    form: {
        group: 'Group',
        title: 'Title',
        description: 'Description',
        roles: 'Roles',
        status: 'Status',
        message: 'Do you want to permanently delete or just lock the app?',
    },
    actions: {
        cancel: 'Cancel',
        close: 'Close',
        save: 'Save',
        edit: 'Edit',
        block: 'Lock up',
        notBlock: 'Remove Lock',
        delete: 'Delete',
    },
    notifications: {
        appsAdd: {
            success: 'Successfully added App',
            error: 'Title app already exists',
        },
        appsEdit: {
            success: 'App update successful',
            error: 'Title app already exists',
            confirm: 'You definitely want to delete!',
        },
        appsDelete: {
            success: 'Delete app successfully',
            lock: 'Lock app successfully',
        },
    },
}
