export const rolesForm = {
    breadcrumb: {
        titleRolesAdd: 'Thêm quyền',
        titleAliasDetail: 'Detail ALIAS',
        titleRolesUpdate: 'Cập nhật quyền',
    },
    form: {
        roles: 'Quyền',
        status: 'Trạng thái',
        level: 'Cấp',
    },
    actions: {
        close: 'Đóng',
        cancel: 'Huỷ',
        save: 'Lưu',
    },
}
