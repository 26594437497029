export const appsList = {
    breadcrumb: {
        title: 'App Managers',
    },
    filter: {
        title: 'Title',
        titleOrId: 'Title or Id',
        selectStatus: 'Select Status',
        selectType: 'Select Type',
        selectGroup: 'Select Group',
        selectOwnerGroup: 'Select Owner',
    },
    table: {
        name: 'Name',
        description: 'Description',
        type: 'Type',
        referenceFromApps: 'Reference From Apps',
        status: 'Status',
        actions: 'Actions',
    },
    actions: {
        add: 'Add',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        import: 'Import',
    },
    confirmed: {
        titleDelete: 'Item Delete Confirmation',
        textDelete: 'Are you sure you want to delete this item?',
    },
}
