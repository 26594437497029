import Repository from './Repository'
const resource = ''

export default {
    changePassUserLogin (id, dataBody, tokenLogin) {
        return Repository.post(`${resource}/users/${id}/update_password`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
            data: dataBody,

        })
    },
    getDataConfig (tokenLogin) {
        return Repository.post(`${resource}/config/list`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    updateDataConfig (path, dataBody, tokenLogin) {
        return Repository.post(`${resource}/config/${path}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,

            },
            data: dataBody,

        })
    },
}
