// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const VersionRepository = Repository.get('version')

const state = {
    isShowDialogVersionForm: false,
    dataForm: {
      id: '',
      title: '',
      dateUpdated: '',
      description: '',
    },
    params: {
      offset: 1,
      limit: 10,
    },
    dataItems: [],
    totalItem: 0,
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    mutations: {
      ...mutations,
    },
    actions: {
      setDefaultFormDataVersion(context) {
        const yourDate = new Date()
        context.commit('dataForm', {
          id: '',
          title: '',
          dateUpdated: yourDate.toISOString().split('T')[0],
          description: '',
        })
      },
      async createVersion(context, tokenLogin) {
        // Show Loading
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const dataBody = context.state.dataForm
        // call API post user
        const adminVersionRepository = await VersionRepository.createVersion(dataBody, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        const dataAdminVersionResult = adminVersionRepository.data
        return dataAdminVersionResult
      },
      async fetchDataVersionList(context) {
        // Show Loading
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const params = context.state.params
        // call API get data users
        const adminVersionRepository = await VersionRepository.getDataVersions(params)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        const dataAdminVersionResult = adminVersionRepository.data
        context.commit('dataItems', dataAdminVersionResult.data.list)
        context.commit('totalItem', dataAdminVersionResult.data.total)
      },
      async updateVersion(context, tokenLogin) {
        // Show Loading
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const id = context.state.dataForm.id
        // call API update Version
        const adminVersionRepository = await VersionRepository.updateVersion(id, {
          title: context.state.dataForm.title,
          dateUpdated: context.state.dataForm.dateUpdated,
          description: context.state.dataForm.description,
        }, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        const dataAdminVersionDetail = adminVersionRepository.data
        return dataAdminVersionDetail
      },
    async deleteVersion(context, data) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API delete Version
      const adminVersionRepository = await VersionRepository.deleteVersion(data.id, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminVersionRepository
    },

  },
}
