import { appsList as appsListVi } from './vi/appsList.locale'
import { appsList as appsListEn } from './en/appsList.locale'
//
import { appsForm as appsFormVi } from './vi/appsForm.locale'
import { appsForm as appsFormEn } from './en/appsForm.locale'
export const appsLocale = {
    vi: {
        appsList: appsListVi,
        appsForm: appsFormVi,
    },
    en: {
        appsList: appsListEn,
        appsForm: appsFormEn,
    },
}
