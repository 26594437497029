// Imports
import Vue from 'vue'
import Router from 'vue-router'
// import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

import { ROLES } from '../constant'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  // declare the path for the components page
  routes: [
    layout('Default', [
      route('LoginForm', null, 'login', { onlyWhenLoggedOut: true }),
      route('RegisterForm', null, 'register', { onlyWhenLoggedOut: true }),
      route('VerifyRegisterForm', null, 'verify-register', { onlyWhenLoggedOut: true }),
      route('ResendVerifyRegisterForm', null, 'resend-email', { onlyWhenLoggedOut: true }),
      route('Dashboard', null, '/'),
      route('ManageApps', null, 'manage-apps'),

      route('Version', null, 'version', { notAccessLogin: true }),
      // Pages admin
      route('Profile', null, 'profile'),
      route('UserProfile', null, 'components/profile'),
      route('AdminUser', null, 'admin-user'),
      // route('AdminManagers', null, 'admin-managers/:keyAttribute/:id', { onlyAdmin: true }),
      route('AdminRole', null, 'admin-role', { onlyAdmin: true }),
      route('AdminApp', null, 'admin-app'),
      route('AdminNotifications', null, 'admin-notifications', { onlyAdmin: true }),
      route('AdminSetting', null, 'setting', { onlyAdmin: true }),
      route('AdminVersion', null, 'admin-version', { onlyAdmin: true }),
      route('AdminPackageMedia', null, 'admin-package-media', { onlyAdmin: true }),
      route('AdminStorageDrive', null, 'admin-storage-drive', { onlyAdmin: true }),
      route('AdminGroup', null, 'admin-group'),
      route('ManageMedia', null, 'manage-media'),
      route('Schedule', null, 'schedule'),

      // Components
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),

      // Maps
      route('Google Maps', null, 'maps/google'),
    ]),
  ],
})
// Used to check whether you are logged in to your account or not
router.beforeEach(async (to, from, next) => {
  const notAccessLogin = to?.meta?.notAccessLogin || false
  if (notAccessLogin) {
    return next()
  }
  const onlyWhenLoggedOut = to?.meta?.onlyWhenLoggedOut || false
  const onlyAdmin = to?.meta?.onlyAdmin || false
  // get value from localStorage.setItem('dataUserLogin') component LoginForm
  const stringDataUserLogin = localStorage.getItem('dataUserLogin') || '{}'
  // Convert from style String change into style Object
  const dataUserLogin = JSON.parse(stringDataUserLogin)

  // if have localStorage="OK" then next page Dashboard
  if (localStorage.getItem('loggedIn') === 'OK') {
    if (onlyWhenLoggedOut === true) {
      return next('/')
    }
  // if no localStorage then return page Login
  } else {
    if (onlyWhenLoggedOut === false) {
      return next('/login')
      // return this.$router.go('/login')
    }
  }
  /*
    path error trap router
    if onlyAdmin = true
    if dataUserLogin hava role ADMIN then return link of role admin
    else dataUserLogin no hava role ADMIN then return dashboard
  */
  if (onlyAdmin) {
    if (dataUserLogin.role === ROLES.ADMIN || dataUserLogin.role === ROLES.ROOT) {
      return next()
    } else {
      return next('/')
    }
  } else {
    return next()
  }
})

// router.beforeEach((to, from, next) => {
//   return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
// })

export default router
