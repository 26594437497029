export const versionForm = {
    breadcrumb: {

    },
    form: {

    },
    actions: {
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        edit: 'Edit',
        create: 'Add',
    },
    notifications: {
      error: {
        errorDescription: 'Description is required',
      },
  },
}
