// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const PackageMediaRepository = Repository.get('packageMedia')

const state = {
    isShowDialogPackageMediaForm: false,
    dataForm: {
      id: '',
      title: '',
      maxTotalFileSizeMedia: 209715201,
      mimeTypeImage: '',
      maxFileSizeImage: 209715203,
      mimeTypeDocument: '',
      maxFileSizeDocument: 209715203,
      mimeTypeVideo: '',
      maxFileSizeVideo: 209715203,
    },
    params: {
      offset: 1,
      limit: 10,
    },
    dataItems: [],
    totalItem: 0,
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    mutations: {
      ...mutations,
    },
    actions: {
      setDefaultFormDataPackageMedia(context) {
        context.commit('dataForm', {
          id: '',
          title: '',
          maxTotalFileSizeMedia: 209715201,
          mimeTypeImage: 'image',
          maxFileSizeImage: 209715203,
          mimeTypeDocument: 'application',
          maxFileSizeDocument: 209715203,
          mimeTypeVideo: 'video',
          maxFileSizeVideo: 209715203,
        })
      },
      async createPackageMedia(context, tokenLogin) {
        // Show Loading
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const dataBody = context.state.dataForm
        const attributeNumber = [
            'maxFileSizeImage',
            'maxFileSizeDocument',
            'maxFileSizeVideo',
            'maxTotalFileSizeMedia',
        ]
        attributeNumber.forEach(element => {
            if (!Number.isNaN(dataBody[element])) {
                dataBody[element] = +dataBody[element]
            }
        });
        // call API post user
        const adminPackageMediaRepository = await PackageMediaRepository.createPackageMedia(dataBody, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        const dataAdminPackageMediaResult = adminPackageMediaRepository.data
        return dataAdminPackageMediaResult
      },
      async fetchDataPackageMediaList(context, tokenLogin) {
        // Show Loading
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const params = context.state.params
        // call API get data users
        const adminPackageMediaRepository = await PackageMediaRepository.getDataPackageMedias(params, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        const dataAdminPackageMediaResult = adminPackageMediaRepository.data
        context.commit('dataItems', dataAdminPackageMediaResult.data.list)
        context.commit('totalItem', dataAdminPackageMediaResult.data.total)
      },
      async updatePackageMedia(context, tokenLogin) {
        // Show Loading
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const dataBody = JSON.parse(JSON.stringify(context.state.dataForm));
        const attributeNumber = [
            'maxFileSizeImage',
            'maxFileSizeDocument',
            'maxFileSizeVideo',
            'maxTotalFileSizeMedia',
        ]
        attributeNumber.forEach(element => {
            if (!Number.isNaN(dataBody[element])) {
                dataBody[element] = +dataBody[element]
            }
        });
        const id = context.state.dataForm.id
        // call API update PackageMedia
        const adminPackageMediaRepository = await PackageMediaRepository.updatePackageMedia(id, {
          ...dataBody,
        }, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        const dataAdminPackageMediaDetail = adminPackageMediaRepository.data
        return dataAdminPackageMediaDetail
      },
    async deletePackageMedia(context, data) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API delete PackageMedia
      const adminPackageMediaRepository = await PackageMediaRepository.deletePackageMedia(data.id, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminPackageMediaRepository
    },

  },
}
