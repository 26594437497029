import Repository from './Repository'
const resource = ''

export default {
    getAPIKey () {
        return Repository.get(`${resource}/api-key`)
    },
    register (dataBody, apiKey) {
        return Repository.post(`${resource}/register`, dataBody, {
            headers: {
                apiKey: apiKey,
            },
        })
    },
    verifyRegister (dataBody, userId) {
        return Repository.post(`${resource}/users/${userId}/verify-account`, dataBody)
    },
    resendCodeEmail (email) {
        return Repository.post(`${resource}/users/${email}/verify-account-again`)
    },
}
