// Utilities
import { make } from 'vuex-pathify'
import { isEmpty, isEqual, xorWith } from 'lodash';
import Repository from '@/repository/RepositoryFactory'
const ScheduleRepository = Repository.get('schedule')
const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const state = {
  params: {
    offset: 1,
    limit: 10,
    appId: '',
    userId: '',
    type: '',
  },
  dataItems: [],
  totalItem: 0,
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations: {
    ...mutations,
  },
  actions: {
    async fetchDataScheduleList(context, tokenLogin) {
      // Show Loading
      const params = context.state.params
      // call API get data schedule
      const scheduleRepository = await ScheduleRepository.getDataSchedules(params, tokenLogin)
      const scheduleResult = scheduleRepository.data
      if (!isArrayEqual(context.state.dataItems, scheduleResult.data.list)) {
        context.commit('dataItems', scheduleResult.data.list)
        context.commit('totalItem', scheduleResult.data.total)
      }
    },
    async downloadFile(context, { tokenLogin, id }) {
      context.commit('layout/isShowProgressCircular', true, { root: true })
      await ScheduleRepository.downloadFile(id, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
    },
  },
}
