// Utilities
import { make } from 'vuex-pathify'

const state = {
    isShowProgressCircular: false,
    limitOptions: [
        5,
        10,
        20,
        50,
        100,
    ],
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    mutations: {
        ...mutations,
    },
    // mutations,
    // actions,
    // ,
}
