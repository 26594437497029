import { versionList as versionListVi } from './vi/versionList.locale'
import { versionList as versionListEn } from './en/versionList.locale'
//
import { versionForm as versionFormVi } from './vi/versionForm.locale'
import { versionForm as versionFormEn } from './en/versionForm.locale'
export const versionLocale = {
    vi: {
        versionList: versionListVi,
        versionForm: versionFormVi,
    },
    en: {
        versionList: versionListEn,
        versionForm: versionFormEn,
    },
}
