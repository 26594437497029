export const apiList = {
    breadcrumb: {
        title: 'API Management',
    },
    table: {
        no: 'No',
        permissions: 'Permissions',
        roles: 'Roles',
        path: 'Path',
        methods: 'Methods',
        description: 'Description',
    },
    part: {
        auth: 'Auth',
        roles: 'Roles',
        user: 'User',
        app: 'App',
        config: 'Config',
        alias: 'Alias',
    },
}
