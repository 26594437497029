<template>
  <v-dialog
    v-model="isShowProgressCircular"
    persistent
  >
    <v-progress-circular
      :size="70"
      color="purple"
      indeterminate
    />
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  export default {
    name: 'ProgressCircular',
    computed: {
      ...sync('layout', [
        'isShowProgressCircular',
      ]),
    },
  }
</script>

<style scoped>
  div /deep/ .v-dialog {
    box-shadow: none;
    overflow: visible;
    text-align: center;
  }
</style>
