// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const AdminAliasRepository = Repository.get('adminAlias')
const AdminAppRepository = Repository.get('adminApp')
const AccessAppRepository = Repository.get('accessApp')

const state = {
    isShowDialogAliasForm: false,
    dataAlias: [],
    totalAlias: 0,
    params: {
        offset: 1,
        limit: 10,
        groupId: '',
    },
    getAppIdAlias: '',
    dataForm: {
        id: '',
        slug: '',
        title: '',
        description: '',
        groupId: '',
        query: null,
        isPublic: true,
    },
    dataQuery: [],
    dataApps: [],
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    getters: {
        getIsShowDialogAliasForm: state => {
            return state
        },
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async fetchDataAliasList (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const params = context.state.params
            // call API get data alias list
            const adminAliasRepository = await AdminAliasRepository.getAliasList(params, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAliasResult = adminAliasRepository.data
            context.commit('dataAlias', dataAdminAliasResult.data.list)
            context.commit('totalAlias', dataAdminAliasResult.data.total)
        },
        setDefaultFormDataAlias (context) {
            context.commit('dataForm', {
                id: '',
                slug: '',
                title: '',
                description: '',
                dataQuery: [],
                isPublic: true,
            })
        },
        async createAlias (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const data = context.state.dataForm
            const dataBody = {
                slug: data.slug,
                title: data.title,
                description: data.description,
                groupId: context.state.params.groupId,
                dataQuery: data.dataQuery,
                isPublic: data.isPublic,
            }
            // call API create Alias
            const adminAliasRepository = await AdminAliasRepository.createAlias(dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAliasResult = adminAliasRepository.data
            return dataAdminAliasResult
        },
        async updateAlias (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            const data = context.state.dataForm
            const dataBody = {
                slug: data.slug,
                title: data.title,
                description: data.description,
                dataQuery: data.dataQuery,
                isPublic: data.isPublic,
            }
            // call API update Alias
            const adminAliasRepository = await AdminAliasRepository.updateAlias(id, dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAliasResult = adminAliasRepository.data
            return dataAdminAliasResult
        },
        async deleteAlias (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            // call API delete Alias
            await AdminAliasRepository.deleteAlias(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
        },
        async getDataApps(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            // call API get data apps all
            const adminAppRepository = await AdminAppRepository.getDataAppsofUserLogin({ isShowPublic: true }, tokenLogin, false, context.state.params.groupId)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAppResult = adminAppRepository.data
            if (!dataAdminAppResult.data) {
              dataAdminAppResult.data = {
                list: [],
                total: 0,
              }
            }
            return dataAdminAppResult.data.list
        },
        async getAllDataAppItems(context, { dataSeletectedApp = {}, tokenLogin = '' }) {
            const params = {
                limit: 500,
            }
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const resultAPI = await AccessAppRepository.getDataListApp(params, {
                app_id: dataSeletectedApp.id,
                secretkey: dataSeletectedApp.secretkey,
                group_id: dataSeletectedApp?.dataGroup?.id,
                group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
            }, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return resultAPI?.data?.data?.list || []
        },
    },
    // mutations,
    // ,
}
