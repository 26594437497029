import Repository from './Repository'
import axios from 'axios'
const resource = 'access'
const baseURL = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_VERSIONS_BASE_URL}` // Incase of /api/v1;

export default {
    getDataListApp (params, dataHeaders, tokenLogin) {
        return Repository.post(`${resource}/list`, {}, {
            params,
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
                app_id: dataHeaders.appId,
                secretKey: dataHeaders.secretKey,
                app_child: dataHeaders.appChild,
            },
        })
    },
  //   getDataListApp (params, dataHeaders, tokenLogin) {
  //     const res = axios({
  //       method: 'post',
  //       url: 'https://api.techupcorp.com/v3/access/list',
  //       data: {
  //         "headers": {
  //           "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiRWhDVjNJc0ItcTNFXzNKVjhlWF8iLCJlbWFpbCI6InJvb3RAdHQuY29tIiwicm9sZSI6IlJvb3QiLCJncm91cF9pZCI6IiIsImdyb3VwX3NlY3JldGtleSI6IiIsImlhdCI6MTcwMDg3OTI4NCwiZXhwIjoxNzAxNDg0MDg0fQ.o3sP9HaMZoCFODeUBtWhTLDWmUgeF5sVS__4geaRccA",
  //           "app_id": "-g9884oB2XKsMBYdbpzS",
  //           "secretkey": "U2FsdGVkX187df+r4fdWF/U2ipxV2PrnfP9Wx4E1+td9niop7qcGf7wFtgsAg/r5nkC3C6CqesinhLERpSPLd2mVvz+I",
  //           "group_id": "BOqwtooBageTCWyELplt",
  //           "group_secretkey": "U2FsdGVkX188D88nmNRHKXnmBHypTd4Sgnx+B7Cae+QwNBEbULb0Yp48YnFmIFAAyx36aX+zCLu/9aikcsLWj3RRVmLI7NM="
  //       },
  //       "params": {
  //           "offset": 1
  //       }
  //       },
  //     })
  //     console.log('res')
  //     return res

  // },
}
