// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const GroupRepository = Repository.get('group')
const AdminAppRepository = Repository.get('adminApp')
const ScheduleRepository = Repository.get('schedule')

const state = {
    isShowDialogGroupForm: false,
    dataForm: {
      id: '',
      title: '',
      type: '',
      image_url: '',
      imageData: '',
      description: '',
      whiteList: '',
      appIds: [],
      isApprovalRequired: true,
      wattingUserIds: [],
      banUserIds: [],
      groupUserIds: [],
      userIds: [],
      managerUsers: [],
      packageMinio: '',
      packageMedia: '',
      secretkey: '',
      referenceToGroups: [],
      accessableGroups: [],
    },
    params: {
      offset: 1,
      limit: 10,
      titleOrId: '',
      type: '',
      createdBy: '',
    },
    allDataApps: [],
    allDataGroups: [],
    allDataUsers: [],
    allDataManagerUsers: [],
    allDataGroupUsers: [],
    dataItems: [],
    totalItem: 0,
    isUpdateGroup: false,
    optionTypes: [{ text: 'Protected', value: 'protected' }, { text: 'Private', value: 'private' }, { text: 'Public', value: 'public' }],
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations: {
    ...mutations,
  },
  actions: {
    setDefaultFormDataGroup(context) {
      context.commit('dataForm', {
        id: '',
        title: '',
        type: 'private',
        image_url: '',
        imageData: '',
        description: '',
        whiteList: '',
        appIds: [],
        isApprovalRequired: true,
        wattingUserIds: [],
        banUserIds: [],
        groupUserIds: [],
        managerUsers: [],
        userIds: [],
        packageMinio: '',
        packageMedia: '',
        secretkey: '',
        referenceToGroups: [],
        accessableGroups: [],
      })
    },
    async getDataAppsNotGroup(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API get data apps all
      const adminAppRepository = await AdminAppRepository.getDataAppsNotGroup(tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminAppResult = adminAppRepository.data
      if (!dataAdminAppResult.data) {
        dataAdminAppResult.data = {
          list: [],
          total: 0,
        }
      }
      return dataAdminAppResult.data.list
    },
    async createGroup(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const dataBody = context.state.dataForm
      // call API post user
      const adminGroupRepository = await GroupRepository.createGroup(dataBody, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminGroupResult = adminGroupRepository.data
      return dataAdminGroupResult
    },
    async fetchDataGroupList(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const params = context.state.params
      // call API get data users
      const adminGroupRepository = await GroupRepository.getDataGroups(params, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminGroupResult = adminGroupRepository.data
      context.commit('dataItems', dataAdminGroupResult.data.list)
      context.commit('totalItem', dataAdminGroupResult.data.total)
    },
    async getAllDataGroups(context, tokenLogin) {
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const adminGroupRepository = await GroupRepository.getDataGroups({
        offset: 1,
        limit: 1000,
      }, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminGroupResult = adminGroupRepository.data
      if (!dataAdminGroupResult.data) {
        dataAdminGroupResult.data = {
          list: [],
          total: 0,
        }
      }
      return dataAdminGroupResult.data.list
    },
    async updateGroup(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const id = context.state.dataForm.id
      // call API update Group
      const adminGroupRepository = await GroupRepository.updateGroup(id, {
        ...context.state.dataForm,
      }, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminGroupDetail = adminGroupRepository.data
      return dataAdminGroupDetail
    },
    async deleteGroup(context, data) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API delete Group
      const adminGroupRepository = await GroupRepository.deleteGroup(data.id, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminGroupRepository
    },
    async resetSecretKeyGroup(context, data) {
       // Show Loading
       context.commit('layout/isShowProgressCircular', true, { root: true })
       // call API reset Sceret Key Group
       const adminGroupRepository = await GroupRepository.resetSecretKeyGroup(data.id, data.tokenLogin)
       context.commit('layout/isShowProgressCircular', false, { root: true })
       return adminGroupRepository
    },
    async exportGroup(context, data) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API export Group
      const adminGroupRepository = await ScheduleRepository.createSchedule({
        modelId: data.id,
        type: 'export_group_data',
      }, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminGroupRepository
    },
    async importGroup(context, data) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API import Group
      const adminGroupRepository = await ScheduleRepository.importFile(data.formData, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminGroupRepository
    },
  },
}
