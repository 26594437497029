import Repository from './Repository'
const resource = ''

export default {
    getDataMe (tokenLogin) {
        return Repository.post(`${resource}/verify`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
}
