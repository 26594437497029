import { rolesList as rolesListVi } from './vi/rolesList.locale'
import { rolesList as rolesListEn } from './en/rolesList.locale'
//
import { rolesForm as rolesFormVi } from './vi/rolesForm.locale'
import { rolesForm as rolesFormEn } from './en/rolesForm.locale'
//
import { apiList as apiListVi } from './vi/apiList.locale'
import { apiList as apiListEn } from './en/apiList.locale'
export const rolesLocale = {
    vi: {
        rolesList: rolesListVi,
        rolesForm: rolesFormVi,
        apiList: apiListVi,
    },
    en: {
        rolesList: rolesListEn,
        rolesForm: rolesFormEn,
        apiList: apiListEn,
    },
}
