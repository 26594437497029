import { login as loginVi } from './vi/login.locale'
import { login as loginEn } from './en/login.locale'
//
import { signUp as signUpVi } from './vi/signUp.locale'
import { signUp as signUpEn } from './en/signUp.locale'
export const authLocale = {
    vi: {
        login: loginVi,
        signUp: signUpVi,
    },
    en: {
        login: loginEn,
        signUp: signUpEn,
    },
}
