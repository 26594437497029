import { packageMediaList as packageMediaListVi } from './vi/packageMediaList.locale'
import { packageMediaList as packageMediaListEn } from './en/packageMediaList.locale'
//
import { packageMediaForm as packageMediaFormVi } from './vi/packageMediaForm.locale'
import { packageMediaForm as packageMediaFormEn } from './en/packageMediaForm.locale'
export const packageMediaLocale = {
    vi: {
        packageMediaList: packageMediaListVi,
        packageMediaForm: packageMediaFormVi,
    },
    en: {
        packageMediaList: packageMediaListEn,
        packageMediaForm: packageMediaFormEn,
    },
}
