import Repository from './Repository'
const resource = 'access'
export default {
    getDataListApp (params, dataHeaders = {}, tokenLogin, queryFilter = {}) {
      const res = Repository.post(`${resource}/list`, {
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          ...dataHeaders,
      },
      params: {
          offset: params.offset,
          limit: params.limit,
      },
      data: {
        ...queryFilter,
      },
    })
        return res
    },
    deleteItem (dataHeaders = {}, tokenLogin) {
      return Repository.post(`${resource}/delete`, {
          headers: {
              Authorization: `Bearer ${tokenLogin}`,
              ...dataHeaders,
          },
      })
  },

    updateItem (dataBody, dataHeaders = {}, tokenLogin) {
        return Repository.post(`${resource}/update`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
                ...dataHeaders,
            },
            data: dataBody,
        })
    },
    createItem (dataBody, dataHeaders = {}, tokenLogin) {
        return Repository.post(`${resource}/create`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
                ...dataHeaders,
            },
            data: dataBody,
        })
    },
}
