export const userForm = {
    breadcrumb: {
        titleUserAdd: 'Add user',
        titleUserDetail: 'Detail user',
        titleUserUpdate: 'Update user',
        titleUserConfirm: 'Confirm',
    },
    form: {
        email: 'E-mail',
        name: 'Name',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        roles: 'Roles',
        status: 'Status',
        actions: 'Actions',
        message: 'Do you want to permanently delete or just lock the user?',
    },
    actions: {
        close: 'Close',
        save: 'Save',
        saveAddChild: 'Save Add Child',
        updateChildItem: 'Update Child Item',
        saveAndMore: 'Save And More',
        edit: 'Edit',
        block: 'Lock Up',
        notBlock: 'Remove Lock',
        delete: 'Delete',
    },
    notifications: {
        userAdd: {
            success: 'Successfully added account',
        },
        userEdit: {
            success: 'User update successful',
        },
        userDelete: {
            success: 'Delete user successfully',
            lock: 'User lock successful',
            unlock: 'Unblock user successfully',
        },
        error: {
          '001.001.011': 'Your password must be between 8 - 20 characters, including uppercase letters, lowercase letters, numbers and special characters (! @ # $, etc.), but cannot contain spaces',
          '001.001.004': 'Email is not in correct format',
        },
    },
}
