import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
// import { capitalize } from 'lodash'
import handle from './handleAppAdmin'
const AdminAppRepository = Repository.get('adminApp')
const ScheduleRepository = Repository.get('schedule')
let listDataApp = [] // chưa id, tên app của từng trang

const state = {
    isUpdateApp: false,
    // component HeaderForm
    isShowDialogAppForm: false,
    isShowDialogAppFormImport: false,
    isShowDialogAppFormImportData: false,
    // component Table
    isShowDialogAppFormUpdate: false,
    isShowDialogAppFormEdit: false,
    isShowDialogAppFormDetail: false,
    isShowDialogAppFormDelete: false,
    // use for component DialogAppFormImportData
    getIdImportDataApp: '',
    // component Table and FilterForm
    dataApps: [],
    imagePreview: '',
    // use for component FilterForm User
    listAllDataApp: [],
    allDataApps: [],
    totalApp: 0,
    params: {
        offset: 1,
        limit: 10,
        title: '',
        titleOrId: '',
        status: '',
        type: '',
    },
    dataForm: {
        id: '',
        groupId: '',
        title: '',
        type: '',
        description: '',
        image_url: '',
        imageData: '',
        secretkey: '',
        metadata: [],
        arrAppReference: [],
        arrAppAccess: [],
        packageMedia: '',
        packageMinio: '',
        isDataExist: false,
        removeField: [],
        // arrAccessableApps: [],
    },
}

const mutations = make.mutations(state)
/**
 * referenceToApps
 * externalReferenceableFields
 */
const getDataDuplicate = (referenceToApps = [], externalReferenceableFields = []) => {
    const data = []
    externalReferenceableFields.map((item) => {
        const value = referenceToApps.find((x) => Object.is(x.id, item.id))
        if (value !== -1) {
            data.push(value)
        }
    })
    return data
}

export default {
    namespaced: true,
    state,
    getters: {
        getIsShowDialogAppForm: state => {
            return state
        },
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async resetSecretKeyAction (context, tokenLogin) {
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            const secretKey = context.state.dataForm.secretkey
            const adminAppRepository = await AdminAppRepository.resetSecretKey(id, secretKey, tokenLogin)
            // console.log('adminAppRepository resetSecretKeyAction', adminAppRepository)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return adminAppRepository.data
        },
        async fetchDataAppList (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const params = JSON.parse(JSON.stringify(context.state.params))
            if (typeof params.title === 'string') {
                params.title = params.title.toLowerCase()
            }
            // call API get data apps
            const adminAppRepository = await AdminAppRepository.getDataApps(params, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAppResult = adminAppRepository.data
            if (!dataAdminAppResult.data) {
                dataAdminAppResult.data = {
                    list: [],
                    total: 0,
                }
            }
            context.commit('dataApps', dataAdminAppResult.data.list)
            context.commit('totalApp', dataAdminAppResult.data.total)
        },
        async fetchDataAppListAll (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            // call API get data apps all
            const params = {
                limit: 500,
            }
            const adminAppRepository = await AdminAppRepository.getDataApps(params, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAppResult = adminAppRepository.data
            if (!dataAdminAppResult.data) {
                dataAdminAppResult.data = {
                    list: [],
                    total: 0,
                }
            }
            handle.getDataFieldReferenceToApp(dataAdminAppResult.data.list)
            listDataApp = [];
            dataAdminAppResult.data.list.map((item) => {
                listDataApp.push({ ...item, id: item.id, secretKey: item.secretkey, title: item.title, avatar: item.image_url || 'image_url', to: `/admin-managers/${item.id}`, items: item.metadata })
            })
            context.commit('listAllDataApp', listDataApp)
        },
        setDefaultFormDataApp (context) {
            context.commit('dataForm', {
                id: '',
                groupId: '',
                title: '',
                type: 'protected',
                description: '',
                image: '',
                metadata: [],
                referenceToApps: [],
                accessableApps: [],
                packageMedia: '',
                packageMinio: '',
                isDataExist: false,
                removeField: [],
            })
            context.commit('imagePreview')
        },
        async createApp (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const data = context.state.dataForm
            // const dataMetada = handle.convertDataAPI(data.metadata)
            const dataBody = {
                groupId: data.groupId,
                title: data.title,
                image_url: data.image_url,
                imageData: data.imageData,
                type: data.type,
                description: data.description,
                metadata: handle.converDataBackend(data.metadata),
                admin: data.admin,
                manage: data.manage,
                user: data.user,
                referenceToApps: data.referenceToApps,
                accessableApps: data.accessableApps,
                packageMedia: data.packageMedia || '',
                packageMinio: data.packageMinio || '',
                removeField: [],
                // referenceToApps: handle.handleConvertAppReferenceApp(data.arrAppReference, listDataApp),
            }
            // call API create app
            const adminAppRepository = await AdminAppRepository.createApp(dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return adminAppRepository.data
        },
        async lockUpApp (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            await AdminAppRepository.lockUpApp(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
        },
        async deleteApp (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            const adminAppRepository = await AdminAppRepository.deleteApp(id, tokenLogin.tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return adminAppRepository
        },
        async detailApp (context, { tokenLogin, isClone = false }) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            // call API app detail
            const adminAppRepository = await AdminAppRepository.getAppsDetail(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminAppDetail = adminAppRepository.data
            const data = {
                id: dataAdminAppDetail.data.id,
                image_url: dataAdminAppDetail.data.image_url || '',
                type: dataAdminAppDetail.data.type,
                title: dataAdminAppDetail.data.title,
                status: dataAdminAppDetail.data.status,
                description: dataAdminAppDetail.data.description,
                admin: dataAdminAppDetail.data.admin,
                secretkey: dataAdminAppDetail.data.secretkey,
                metadata: handle.convertDataClient(dataAdminAppDetail.data.metadata, isClone),
                // dataShow: handle.listNameApp(id, listDataApp),
                dataShow: listDataApp,
                manage: dataAdminAppDetail.data.manage,
                user: dataAdminAppDetail.data.user,
                referenceToApps: dataAdminAppDetail.data.rawReferenceToApps,
                accessableApps: dataAdminAppDetail.data.accessableApps,
                getDuplicate: getDataDuplicate(dataAdminAppDetail.data.referenceToApps, dataAdminAppDetail.data.externalReferenceableFields),
                packageMedia: dataAdminAppDetail.data.packageMedia || '',
                packageMinio: dataAdminAppDetail.data.packageMinio || '',
                dataGroup: dataAdminAppDetail.data?.dataGroup || {},
                isDataExist: dataAdminAppDetail.data?.isDataExist,
                groupId: dataAdminAppDetail.data?.dataGroup?.id || '',
                removeField: dataAdminAppDetail.data?.removeField || [],
            }
            if (isClone) {
                data.dataGroup = {}
                data.id = ''
                data.referenceToApps = []
                data.accessableApps = []
                data.secretkey = ''
                data.isDataExist = false
            }
            context.commit('imagePreview', data.image_url)
            context.commit('dataForm', data)
        },
        async updateApp (context, tokenLogin) {
            context.commit('layout/isShowProgressCircular', true, { root: true })
            if (context.state.getIdImportDataApp !== '') {
                context.state.dataForm.id = context.state.getIdImportDataApp
            }
            const id = context.state.dataForm.id
            const data = context.state.dataForm
            const dataBody = {
                title: data.title,
                status: data.status,
                imageData: data.imageData,
                description: data.description,
                admin: data.admin,
                type: data.type,
                metadata: handle.converDataBackend(data.metadata),
                manage: data.manage,
                user: data.user,
                referenceToApps: data.referenceToApps,
                accessableApps: data.accessableApps,
                packageMedia: data.packageMedia || '',
                packageMinio: data.packageMinio || '',
                groupId: data.groupId,
                removeField: data.removeField || [],
                // accessableApps: handle.handleConvertAppReferenceApp(data.arrAccessableApps, listDataApp),
            }
            const adminAppRepository = await AdminAppRepository.updateApp(id, dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return adminAppRepository.data
        },
        async exportApp(context, data) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            // call API export Group
            const adminGroupRepository = await ScheduleRepository.createSchedule({
                modelId: data.id,
                type: 'export_app_data',
            }, data.tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return adminGroupRepository
        },
        async importApp(context, data) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            // call API import Group
            const adminGroupRepository = await ScheduleRepository.importFile(data.formData, data.tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            return adminGroupRepository
        },
    },
}
