export const packageMediaList = {
    breadcrumb: {

    },
    filter: {

    },
    table: {
        email: 'Email',
        name: 'Họ tên',
        roles: 'Quyền',
        status: 'Trạng thái',
        actions: 'Hành động',
    },
    actions: {
        add: 'Thêm',
        view: 'Xem',
        edit: 'Sửa',
        delete: 'Xoá',
    },
}
