import Repository from './Repository'
const resource = 'users'

export default {
    getDataUsers(params, tokenLogin, isAllGroup = false) {
        const selectedGroupId = localStorage.getItem('groupId')
        return Repository.post(`${resource}/list`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
            params: {
                ...params,
                ...selectedGroupId && !isAllGroup ? {
                    groupId: selectedGroupId,
                } : {},
            },
        })
    },
    getDataOwnerGroupByUser (tokenLogin) {
      const token = tokenLogin.tokenLogin
        return Repository.post(`${resource}/owner-group`, {}, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
    },
    createUser (dataBody, tokenLogin) {
        return Repository.post(`${resource}`, {
            headers: {
              Authorization: `Bearer ${tokenLogin}`,
          },
          data: dataBody,
        })
    },
    getUsersDetail (id, tokenLogin) {
        return Repository.post(`${resource}/detail/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    updateUsers (id, dataBody, tokenLogin) {
        return Repository.post(`${resource}/update/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
            data: dataBody,
        })
    },
    blockUsers (id, tokenLogin) {
        return Repository.post(`${resource}/block/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    deleteUsers (id, tokenLogin) {
        return Repository.post(`${resource}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    joinGroup (dataBody, tokenLogin) {
        return Repository.post(`${resource}/join-group`, dataBody, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    changeUserStatus (dataBody, tokenLogin) {
        return Repository.post(`${resource}/change-status`, dataBody, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
}
