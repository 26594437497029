import { storageDriveList as storageDriveListVi } from './vi/storageDriveList.locale'
import { storageDriveList as storageDriveListEn } from './en/storageDriveList.locale'
//
import { storageDriveForm as storageDriveFormVi } from './vi/storageDriveForm.locale'
import { storageDriveForm as storageDriveFormEn } from './en/storageDriveForm.locale'
export const storageDriveLocale = {
    vi: {
        storageDriveList: storageDriveListVi,
        storageDriveForm: storageDriveFormVi,
    },
    en: {
        storageDriveList: storageDriveListEn,
        storageDriveForm: storageDriveFormEn,
    },
}
