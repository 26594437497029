// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const StorageDriveRepository = Repository.get('storageDrive')

const state = {
    isShowDialogStorageDriveForm: false,
    dataForm: {
      id: '',
      title: '',
      key: '',
      secret: '',
      region: '',
      prefixBucket: '',
      endpoint: '',
    },
    params: {
      offset: 1,
      limit: 10,
    },
    dataItems: [],
    totalItem: 0,
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations: {
    ...mutations,
  },
  actions: {
    setDefaultFormDataStorageDrive(context) {
      context.commit('dataForm', {
        id: '',
        title: '',
        key: '',
        secret: '',
        region: '',
        prefixBucket: '',
        endpoint: '',
      })
    },
    async createStorageDrive(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const dataBody = context.state.dataForm
      // call API post user
      const adminStorageDriveRepository = await StorageDriveRepository.createStorageDrive(dataBody, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminStorageDriveResult = adminStorageDriveRepository.data
      return dataAdminStorageDriveResult
    },
    async fetchDataStorageDriveList(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const params = context.state.params
      // call API get data users
      const adminStorageDriveRepository = await StorageDriveRepository.getDataStorageDrives(params, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminStorageDriveResult = adminStorageDriveRepository.data
      context.commit('dataItems', dataAdminStorageDriveResult.data.list)
      context.commit('totalItem', dataAdminStorageDriveResult.data.total)
    },
    async updateStorageDrive(context, tokenLogin) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const id = context.state.dataForm.id
      // call API update StorageDrive
      const adminStorageDriveRepository = await StorageDriveRepository.updateStorageDrive(id, {
        ...context.state.dataForm,
      }, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminStorageDriveDetail = adminStorageDriveRepository.data
      return dataAdminStorageDriveDetail
    },
    async deleteStorageDrive(context, data) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API delete StorageDrive
      const adminStorageDriveRepository = await StorageDriveRepository.deleteStorageDrive(data.id, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminStorageDriveRepository
    },
  },
}
