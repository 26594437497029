const LANG_KEY = 'lang'

const TokenService = {
    getLang () {
        return localStorage.getItem(LANG_KEY)
    },
    setLang (lang) {
        localStorage.setItem(LANG_KEY, lang)
    },
}

export default TokenService
