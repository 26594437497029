export const versionForm = {
  breadcrumb: {

  },
  form: {

  },
  actions: {
      close: 'Close',
      cancel: 'Cancel',
      save: 'Lưu',
      edit: 'Sửa',
      create: 'Thêm',
  },
  notifications: {
    error: {
      errorDescription: 'Mô tả là bắt buộc',
    },
},
}
