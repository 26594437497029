import Repository from './Repository'
const resource = ''

export default {
    getAPIKey () {
        return Repository.get(`${resource}/api-key`)
    },
    login (dataBody, apiKey) {
        return Repository.post(`${resource}/login`, {
            headers: {
                apiKey: apiKey,
            },
            data: dataBody,
        })
    },
    authCode (dataBody, apiKey) {
        return Repository.post(`${resource}/auth-code`, dataBody, {
            headers: {
                apiKey: apiKey,
            },
        })
    },
}
