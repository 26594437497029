export const rolesList = {
    breadcrumb: {
        title: 'Roles Management',
    },
    table: {
        roles: 'Roles',
        level: 'Level',
        creationTime: 'Creation Time',
        status: 'Status',
        actions: 'Actions',
    },
    actions: {
        add: 'Add',
        edit: 'Edit',
        detail: 'Detail',
        block: 'Lock Up',
    },
}
