import Repository from './Repository'
const resource = 'package-media'

export default {
  createPackageMedia(dataBody, tokenLogin) {
    return Repository.post(`${resource}/create`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: dataBody,
    })
  },
  getDataPackageMedias(params, tokenLogin) {
    return Repository.post(`${resource}/list`, {
      params,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  updatePackageMedia(id, dataBody, tokenLogin) {
    return Repository.post(`${resource}/update/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: dataBody,
    })
  },
  deletePackageMedia(id, tokenLogin) {
    return Repository.post(`${resource}/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
}
