import Repository from './Repository'
// const resource = 'package-minio'
const resource = 'storage-drive'

export default {
  createStorageDrive(dataBody, tokenLogin) {
    return Repository.post(`${resource}`, dataBody, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  getDataStorageDrives(params, tokenLogin) {
    return Repository.post(`${resource}/list`, {
      params,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  updateStorageDrive(id, dataBody, tokenLogin) {
    return Repository.post(`${resource}/update/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: dataBody,
    })
  },
  deleteStorageDrive(id, tokenLogin) {
    return Repository.post(`${resource}/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
}
