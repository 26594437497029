export const layoutLocale = {
    vi: {
        sidebar: {
            dashboard: 'Bảng điều khiển',
            users: 'Người dùng',
            managers: 'Quản lý',
            manageApps: 'Cơ sở dữ liệu',
            apps: 'Ứng dụng',
            notifications: 'Thông báo',
            settings: 'Cài đặt',
            roles: 'Vai trò',
            version: 'Phiên bản',
            packageMedia: 'Gói Quản lý Media',
            storageDrive: 'Vùng Lưu Trữ',
            manageMedia: 'Quản lý Media',
            group: 'Nhóm',
            schedule: 'Lịch trình',
        },
        appbar: {
            logout: 'Đăng xuất',
        },
    },
    en: {
        sidebar: {
            dashboard: 'Dashboard',
            users: 'Users',
            managers: 'Managers',
            manageApps: 'Manage Data Apps',
            apps: 'Apps',
            notifications: 'Notifications',
            settings: 'Settings',
            roles: 'Roles',
            version: 'Version',
            packageMedia: 'Package Media',
            storageDrive: 'Storage Drive',
            manageMedia: 'Manage Media',
            group: 'Group',
            schedule: 'Schedule',
        },
        appbar: {
            logout: 'Log out',
        },
    },
}
