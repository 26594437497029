export const rolesList = {
    breadcrumb: {
        title: 'Quản lý quyền',
    },
    table: {
        roles: 'Quyền',
        level: 'Cấp',
        creationTime: 'Thời gian tạo',
        status: 'Trạng thái',
        actions: 'Hành động',
    },
    actions: {
        add: 'Thêm',
        edit: 'Sửa',
        detail: 'Chi tiết',
        block: 'Khoá',
    },
}
