import { groupList as groupListVi } from './vi/groupList.locale'
import { groupList as groupListEn } from './en/groupList.locale'
//
import { groupForm as groupFormVi } from './vi/groupForm.locale'
import { groupForm as groupFormEn } from './en/groupForm.locale'
export const groupLocale = {
    vi: {
        groupList: groupListVi,
        groupForm: groupFormVi,
    },
    en: {
        groupList: groupListEn,
        groupForm: groupFormEn,
    },
}
