import Repository from './Repository'
const resource = 'media'
export default {
  uploadFile(formData, appId, tokenLogin) {
    return Repository.post(`${resource}/${appId}/upload-file`, formData, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  getImageFiles(appId, tokenLogin) {
    return Repository.post(`${resource}/${appId}/image-files/list`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  getFiles(appId, userId, tokenLogin) {
    return Repository.post(`${resource}/${appId}/files`, {
      params: {
        userId,
      },
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  deleteFile(appId, nameFile, tokenLogin) {
    return Repository.post(`${resource}/${appId}/file-delete/${nameFile}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    });
  },
}
