export const storageDriveList = {
    page: {
      title: 'Storage Drive Management',
    },
    filter: {

    },
    table: {
      title: 'Title',
      key: 'Key',
      secret: 'Secret',
      region: 'Region',
      prefixBucket: 'Prefix Bucket',
      endpoint: 'Endpoint',
      updatedAt: 'Updated At',
      actions: 'Actions',
    },
}
