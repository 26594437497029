export const apiList = {
    breadcrumb: {
        title: 'Quản lý API',
    },
    table: {
        no: 'Phần',
        permissions: 'Quyền',
        roles: 'Vai trò',
        path: 'Đường dẫn',
        methods: 'Phương thức',
        description: 'Mô tả',
    },
    part: {
        auth: 'Xác thực',
        roles: 'Quyền',
        user: 'Người dùng',
        app: 'Ứng dụng',
        config: 'Cấu hình',
        alias: 'Alias',
    },
}
