import RegisterRepository from './RegisterRepository'
import LoginRepository from './LoginRepository'
import AdminUserRepository from './AdminUserRepository'
import AdminRoleRepository from './AdminRoleRepository'
import UserRepository from './UserRepository'
import AdminAppRepository from './AdminAppRepository'
import AdminManagerRepository from './AdminManagerRepository'
import AdminSettingRepository from './AdminSettingRepository'
import AdminAliasRepository from './AdminAliasRepository'
import AccessAppRepository from './AccessAppRepository'
import VersionRepository from './VersionRepository'
import MediaRepository from './MediaRepository'
import PackageMediaRepository from './PackageMediaRepository'
import StorageDriveRepository from './StorageDriveRepository'
import GroupRepository from './GroupRepository'
import ScheduleRepository from './ScheduleRepository'

const repositories = {
    register: RegisterRepository,
    login: LoginRepository,
    adminUser: AdminUserRepository,
    adminRole: AdminRoleRepository,
    userProfile: UserRepository,
    adminApp: AdminAppRepository,
    adminManager: AdminManagerRepository,
    adminSetting: AdminSettingRepository,
    adminAlias: AdminAliasRepository,
    accessApp: AccessAppRepository,
    version: VersionRepository,
    media: MediaRepository,
    packageMedia: PackageMediaRepository,
    storageDrive: StorageDriveRepository,
    group: GroupRepository,
    schedule: ScheduleRepository,
}

export default {
    get: name => repositories[name],
}
