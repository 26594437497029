export const groupList = {
    breadcrumb: {

    },
    filter: {

    },
    table: {
        email: 'Email',
        name: 'Name',
        roles: 'Roles',
        status: 'Status',
        actions: 'Actions',
    },
    actions: {
        add: 'Add',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
        export: 'Export',
        import: 'Import',
    },
}
