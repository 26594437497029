// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const AdminSettingRepository = Repository.get('adminSetting')

const state = {
    // component ChangePassword
    isShowDialogSettingFormPassword: false,
    dataForm: {
        id: '',
        passwordCurrent: '',
        password: '',
        passwordConfirm: '',
    },
    dataFormPassword: {
      id: '',
      passwordCurrent: '',
      password: '',
      passwordConfirm: '',
  },
    authenticationConfig: {
        ClientIDGg: '',
        IDAPPFACEBOOK: '',
        authEmail: true,
        authCodeEmail: false,
        authPhone: false,
        authCodePhone: false,
    },
    serverConfig: {},
    emailConfig: {},
    minioConfig: {},
    defaultMediaConfig: {
        maxTotalFileSizeMedia: 209715201,
        mimeTypeImage: 'image',
        maxFileSizeImage: 209715203,
        mimeTypeDocument: 'application',
        maxFileSizeDocument: 209715203,
        mimeTypeVideo: 'video',
        maxFileSizeVideo: 209715203,
    },
    isShowDialogSettingFormTestEmail: false,
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    getters: {
        getIsShowDialogSettingFormPassword: state => {
            return state
        },
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async changePassUserLogin(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataFormPassword.id
            const dataBody = context.state.dataFormPassword
            // call API change password user login
            const adminSettingRepository = await AdminSettingRepository.changePassUserLogin(id, dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },
        async fetchDataConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            // call API get data config
            const adminSettingRepository = await AdminSettingRepository.getDataConfig(tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            context.commit('dataForm', dataAdminSettingResult.data.modifiable)
            context.commit('authenticationConfig', dataAdminSettingResult.data.authenticationConfig)
            context.commit('serverConfig', dataAdminSettingResult.data.serverConfig)
            context.commit('emailConfig', dataAdminSettingResult.data.emailConfig)
            context.commit('minioConfig', dataAdminSettingResult.data.minioConfig)
            context.commit('defaultMediaConfig', {
                maxTotalFileSizeMedia: 209715201,
                mimeTypeImage: 'image',
                maxFileSizeImage: 209715203,
                mimeTypeDocument: 'application',
                maxFileSizeDocument: 209715203,
                mimeTypeVideo: 'video',
                maxFileSizeVideo: 209715203,
                ...dataAdminSettingResult.data.defaultMediaConfig,
            })
        },
        async updateDataConfig(context, tokenLogin) {
            // Show Loading
            // context.commit('layout/isShowProgressCircular', true, { root: true })
            // const dataBody = context.state.dataForm
            // dataBody.PORT_MAIL = +dataBody.PORT_MAIL // value type number
            // dataBody.ES_PORT = +dataBody.ES_PORT
            // dataBody.token_duration = +dataBody.token_duration
            // // call API put data config
            // const adminSettingRepository = await AdminSettingRepository.updateDataConfig(dataBody, tokenLogin)
            // context.commit('layout/isShowProgressCircular', false, { root: true })
            // const dataAdminSettingResult = adminSettingRepository.data
            // return dataAdminSettingResult
        },
        async updateAuthenticationConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = context.state.authenticationConfig
            // call API put data config
            const adminSettingRepository = await AdminSettingRepository.updateDataConfig('authentication', dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },

        async updateServerConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })

            const dataBody = context.state.serverConfig
            // call API put data config
            const adminSettingRepository = await AdminSettingRepository.updateDataConfig('server', dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },

        async updateEmailConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = JSON.parse(JSON.stringify(context.state.emailConfig));
            dataBody.port = +dataBody.port // value type number
            // call API put data config
            const adminSettingRepository = await AdminSettingRepository.updateDataConfig('email', dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },

        async updateMinioConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = JSON.parse(JSON.stringify(context.state.minioConfig));
            // call API put data config
            const adminSettingRepository = await AdminSettingRepository.updateDataConfig('minio', dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },

        async updateDefaultMediaConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = JSON.parse(JSON.stringify(context.state.defaultMediaConfig));
            const attributeNumber = [
                'maxFileSizeImage',
                'maxFileSizeDocument',
                'maxFileSizeVideo',
                'maxTotalFileSizeMedia',
            ]
            attributeNumber.forEach(element => {
                if (!Number.isNaN(dataBody[element])) {
                    dataBody[element] = +dataBody[element]
                }
            });
            // call API put data config
            const adminSettingRepository = await AdminSettingRepository.updateDataConfig('default-media', dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },

        async sendTestEmailConfig(context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = JSON.parse(JSON.stringify(context.state.emailConfig));
            dataBody.port = +dataBody.port // value type number
            // call API put data config
            const adminSettingRepository = await AdminSettingRepository.updateDataConfig('test-email', dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminSettingResult = adminSettingRepository.data
            return dataAdminSettingResult
        },
    },
}
