export const signUp = {
    signUp: 'Sign Up',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
    phone: 'Phone',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    signIn: 'Sign in',
}
