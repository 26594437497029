import { mdiDelete, mdiHospital } from '@mdi/js'
const typeRef = ['refs_array', 'ref'];
export default {
  handleCheckColumn(value) {
    if ((typeof value.keyAttribute === 'string' && value.keyAttribute.length === 0) ||
      // (typeof value.keywork === 'string' && value.keywork.length === 0) ||
      // (typeof value.minSegmentSize === 'string' && value.minSegmentSize.length === 0) ||
      // (typeof value.required === 'string' && value.required.length === 0) ||
      (typeof value.type === 'string' && value.type.length === 0)) {
      return false
    }
    // if (!Object.is(value.type, 'text') && (typeof value.minSegmentSize === 'string' && value.minSegmentSize.length !== 4 && parseInt(value.minSegmentSize) !== 0)) {
    //   return false
    // }
    // if (Object.is(value.type, 'text') && value.minSegmentSize.length === 4) {
    //   return false
    // }
    return true
  },
  handleCheckDuplicates(list) {
    // lấy ra toàn bộ tên ko kèm theo attribute
    return false;
    // var valueArr = list.map(function (item) { return item.keyAttribute })
    // // kiểm tra cấp 1 thử có phần tử trùng hay không
    // var isDuplicate = valueArr.some(function (item, idx) {
    //   return valueArr.indexOf(item) !== idx
    // })
    // return isDuplicate
  },
  buttonAddField(data) {
    data.push({
      keyAttribute: '',
      required: '',
      type: '',
      // keywork: '',
      // minSegmentSize: '',
      keyAttributeForignKey: '',
      view: 'true',
    })
    return data
  },
  optionShow: {
    dataTree: true,
    dataTreeChildField: 'metadata',
    movableColumns: true,
    buttonCross: true,
    columnHeaderVertAlign: 'bottom',
    height: '100%',
    resizableColumns: false,
    layout: 'fitColumns',
    headerSort: false,
    columns: [
      {
        title: 'Field',
        field: 'keyAttribute',
      },
      {
        title: 'Required',
        field: 'required',
      },
      {
        title: 'Type',
        field: 'type',
      },
      {
        title: 'Referenceable',
        field: 'referenceable',
      },
      {
        title: 'Reference To Field',
        field: 'referenceToField',
        width: 360,
      },
    ],
  },
  handleCheckTable(list, listErrorTable) {
    listErrorTable.length = 0
    var message = []; var flag = true
    // kiểm tra cấp 1
    if (this.handleCheckDuplicates(list)) {
      message.push('There are duplicate rows')
      flag = false
    }
    // kiểm tra cấp 2
    list.map((item) => {
      // kiểm tra trên 1 hàng
      if (!this.handleCheckColumn(item)) {
        message.push('Column is required or error Type is not text then minSegment has no value')
        flag = false
      }
      if (item.metadata && item.metadata.length > 0) {
        if (this.handleCheckDuplicates(item.metadata)) {
          message.push('There are duplicate rows')
          flag = false
        }
        // kiểm tra 1 hàng ở level2
        item.metadata.map((itemM) => {
          if (!this.handleCheckColumn(itemM)) {
            message.push('Column is required or error Type is not text then minSegment has no value')
            flag = false
          }
          if (itemM.metadata && itemM.metadata.length > 0) {
            // kiểm tra nhập phần tử LEVEL_3
            if (this.handleCheckDuplicates(itemM.metadata)) {
              message.push('There are duplicate rows')
              flag = false
            }
            itemM.metadata.map((itemL3) => {
              if (!this.handleCheckColumn(itemL3)) {
                message.push('Column is required or error Type is not text then minSegment has no value')
                flag = false
              }
            })
          }
        })
      }
    })

    if (message.length > 0) {
      message.map((item) => {
        listErrorTable.push(item)
      })
      message.length = 0
      return flag
    }
    return flag
  },
  getDataFieldReferenceToApp(listDataAppAll) {
    this.referenceToApps = JSON.parse(JSON.stringify(listDataAppAll))
  },
  getIdReferenceToApp(referenceToApps) {
    // this.referenceToApps = JSON.parse(JSON.stringify(referenceToApps))
    const getIdReferenceToApp = []
    referenceToApps.map((item) => {
      getIdReferenceToApp.push(item.id)
    })
    return getIdReferenceToApp
  },
  handleOption(handleDelete) {
    const options = {
      dataTree: true,
      dataTreeChildField: 'metadata',
      movableColumns: true,
      height: '100%',
      buttonCross: true,
      columnHeaderVertAlign: 'bottom',
      addRowPos: 'bottom',
      resizableColumns: false,
      layout: 'fitColumns',
      headerSort: false,
      columns: [
        {
          title: 'Field',
          field: 'keyAttribute',
          sorter: 'string',
          editor: true,
          validator: [
            {
              type: function (cell, value, parameters) {
                if (!value || !((/^[A-Za-z\d\s]+$/).test(value))) {
                  return false
                }
                return true
              },
            },
          ],
        },
        {
          title: 'Required',
          field: 'required',
          sorter: 'string',
          // editable: true,
          // editor: 'select',
          validator: [
            {
              type: function (cell, value, parameters) {
                if (!value) {
                  return false
                }
                return true
              },
            },
          ],
          // editorParams: { values: { true: 'true', false: 'false' } },
          editor: function (cell, onRendered, success, cancel) {
            var cellValue = cell.getValue()
            var select = document.createElement('select')
            var array = ['true', 'false']
            select.style.padding = '4px'
            select.style.width = '100%'
            select.style.boxSizing = 'border-box'
            onRendered(function () {
              select.focus()
              select.style.height = '100%'
              select.style.outline = 'none'

              array.map((item) => {
                var opt = document.createElement('option')
                opt.value = item
                opt.text = item
                opt.selected = true
                select.appendChild(opt)
              })
            })
            function onChange() {
              if (select.value !== cellValue) {
                success(select.value)
              } else {
                cancel()
              }
            }
            select.addEventListener('blur', onChange)
            return select
          },
        },
        {
          title: 'Type',
          field: 'type',
          sorter: 'string',
          validator: [
            {
              type: function (cell, value, parameters) {
                if (!value) {
                  return false
                }
                return true
              },
            },
          ],
          editor: function (cell, onRendered, success, cancel) {
            var row = cell.getRow()
            var cellValue = cell.getValue()
            var select = document.createElement('select')
            var array = ['array', 'boolean', 'float', 'rich_text', 'ref', 'object', 'text', 'integer', 'double', 'long']
            if (cell.getRow()._row.modules.dataTree.index > 1) {
              array = ['boolean', 'float', 'rich_text', 'ref', 'text', 'integer', 'double', 'long']
            }

            select.style.padding = '4px'
            select.style.width = '100%'
            select.style.boxSizing = 'border-box'

            onRendered(function () {
              select.focus()
              select.style.height = '100%'
              select.style.outline = 'none'

              array.map((item) => {
                var opt = document.createElement('option')
                opt.value = item
                opt.text = item
                if (Object.is(opt.value, cellValue)) {
                  opt.selected = true
                }
                select.appendChild(opt)
              })
            })
            const onChange = () => {
              if (select.value !== cellValue) {
                success(select.value)
                if (cell.getData() && (Object.is(cell.getData().type.toLowerCase(), 'object') || Object.is(cell.getData().type.toLowerCase(), 'array'))) {
                  row.update({ metadata: [] })// addRow
                }
                if (cell.getData() && !(Object.is(cell.getData().type.toLowerCase(), 'object') || Object.is(cell.getData().type.toLowerCase(), 'array'))) {
                  row.update({ metadata: null })// addRow
                }
              } else {
                cancel()
              }
            }
            select.addEventListener('blur', onChange)
            return select
          },
        },
        {
          title: 'Referenceable',
          field: 'referenceable',
          sorter: 'string',
          validator: [
            {
              type: function (cell, value, parameters) {
                if (!value) {
                  return false
                }
                return true
              },
            },
          ],
          editor: function (cell, onRendered, success, cancel) {
            var cellValue = cell.getValue()
            var select = document.createElement('select')
            var array = ['true', 'false']
            select.style.padding = '4px'
            select.style.width = '100%'
            select.style.boxSizing = 'border-box'
            onRendered(function () {
              select.focus()
              select.style.height = '100%'
              select.style.outline = 'none'

              array.map((item) => {
                var opt = document.createElement('option')
                opt.value = item
                opt.text = item
                opt.selected = true
                select.appendChild(opt)
              })
            })
            function onChange() {
              if (select.value !== cellValue) {
                success(select.value)
              } else {
                cancel()
              }
            }
            select.addEventListener('blur', onChange)
            return select
          },
        },
        {
          title: 'Reference To Field',
          field: 'referenceToField',
          sorter: 'string',
          width: 360,
          validator: [
            {
              type: function (cell, value, parameters) {
                if (!value) {
                  return false
                }
                return true
              },
            },
          ],
          editor: (cell, onRendered, success, cancel) => {
            var cellValue = cell.getValue()
            var select = document.createElement('select')
            var dataAccess = this.referenceToApps
            select.style.padding = '4px'
            select.style.width = '100%'
            select.style.boxSizing = 'border-box'
            onRendered(function () {
              select.focus()
              select.style.height = '100%'
              select.style.outline = 'none'

              dataAccess.map((item) => {
                item.metadata.map((items) => {
                  if (items.referenceable) {
                    var opt = document.createElement('option')
                    opt.text = `${item.title}.${items.keyAttribute}(${item.id})`
                    opt.value = `${item.title}.${items.keyAttribute}(${item.id})`
                    opt.selected = true
                    select.appendChild(opt)
                  }
                })
              })
            })
            function onChange() {
              if (select.value !== cellValue) {
                success(select.value)
              } else {
                cancel()
              }
            }
            select.addEventListener('blur', onChange)
            if (cell.getData() && (Object.is(cell.getData().type.toLowerCase(), 'rich_text')) && (cell.getRow()._row.modules.dataTree.index < 2)) {
              return select
            }
          },
        },
        {
          title: 'Action',
          sorter: 'string',
          columns: [
            {
              // title: 'Add',
              sorter: 'string',
              width: 30,
              cellClick: function (e, cell) {
                var row = cell.getRow()
                if (row.getData().type && (Object.is(row.getData().type.toLowerCase(), 'array') || Object.is(row.getData().type.toLowerCase(), 'object'))) {
                  row.addTreeChild({
                    keyAttribute: '',
                    required: '',
                    type: '',
                    referenceable: '',
                    referenceToFieldValue: '',
                    // keywork: '',
                    // minSegmentSize: '',
                    // keyAttributeForignKey: '',
                    view: 'true',
                  })
                  row.treeExpand()
                }
              },
              formatter: (cell, formatterParams, onRendered) => {
                return (Object.is(cell.getRow().getData().type, 'object') || Object.is(cell.getRow().getData().type, 'array')) ? `<svg role="img" class="v-icon__svg"><path d="${mdiHospital}"></path></svg>` : null
              },
            },
            {
              // title: 'Delete',
              sorter: 'string',
              width: 30,
              cellClick: (e, cell) => {
                handleDelete(cell)
              },
              formatter: (cell, formatterParams, onRendered) => {
                return `<svg role="img" class="v-icon__svg red--text"><path d="${mdiDelete}"></path></svg>`
              },
            },
          ],
        },
      ],
    }
    return options
  },
  converDataBackend(list = [], isClone = false) {
    const data = []; var index; var indexChild
    
    list.map((item) => {
      if (item.referenceable === 'true') {
        item.referenceable = true
      }
      if (Object.is(item.type.toLowerCase(), 'object') || Object.is(item.type.toLowerCase(), 'array')) {
        data.push({
          view: !!Object.is(item.view, 'true'),
          keyAttribute: item.keyAttribute,
          type: item.type,
          required: item.required === true || item.required === 'true',
          referenceable: !!Object.is(item.referenceable, true),
          metadata: [],
        })
      } else if (typeRef.includes(item.type.toLowerCase())) {
        if (!item.referenceToFieldValue || item.referenceToFieldValue === 'none' || item.referenceToFieldValue === 'null' || item.referenceToFieldValue === '') {
          data.push({
            view: !!Object.is(item.view, 'true'),
            keyAttribute: item.keyAttribute,
            type: item.type,
            required: item.required === true || item.required === 'true',
            referenceable: !!Object.is(item.referenceable, true),
          })
        } else {
          const arrayReferenceToFieldValue = item.referenceToFieldValue.split('|');

          data.push({
            view: !!Object.is(item.view, 'true'),
            keyAttribute: item.keyAttribute,
            type: item.type,
            required: item.required === true || item.required === 'true',
            referenceable: !!Object.is(item.referenceable, true),
            referenceToField: arrayReferenceToFieldValue[1] || '',
            referenceToApp: arrayReferenceToFieldValue[0],
          })
        }
      } else {
        data.push({
          view: !!Object.is(item.view, 'true'),
          keyAttribute: item.keyAttribute,
          type: item.type,
          required: item.required === true || item.required === 'true',
          referenceable: !!Object.is(item.referenceable, true),
        })
      }
    })

    list.map((item) => {
      // đưa nội dung phần tử 2 vào
      index = data.findIndex((x) => Object.is(x.keyAttribute, item.keyAttribute))
      if (index !== -1) {
        if (list[index].metadata) {
          list[index].metadata.map((item) => {
            if (Object.is(item.type.toLowerCase(), 'object') || Object.is(item.type.toLowerCase(), 'array')) {
              data[index].metadata.push({
                keyAttribute: item.keyAttribute,
                type: item.type,
                required: item.required === true || item.required === 'true',
                referenceable: !!Object.is(item.referenceable, true),
                metadata: [],
              })
            } else if (typeRef.includes(item.type.toLowerCase())) {
              if (!item.referenceToFieldValue || item.referenceToFieldValue === 'none' || item.referenceToFieldValue === 'null' || item.referenceToFieldValue === '') {
                data[index].metadata.push({
                  keyAttribute: item.keyAttribute,
                  type: item.type,
                  required: item.required === true || item.required === 'true',
                  referenceable: !!Object.is(item.referenceable, true),
                })
              } else {
                const arrayReferenceToFieldValue = item.referenceToFieldValue.split('|');
                data[index].metadata.push({
                  keyAttribute: item.keyAttribute,
                  type: item.type,
                  required: item.required === true || item.required === 'true',
                  referenceable: !!Object.is(item.referenceable, true),
                  referenceToField: arrayReferenceToFieldValue[1] || '',
                  referenceToApp: arrayReferenceToFieldValue[0],
                })
              }
            } else {
              data[index].metadata.push({
                keyAttribute: item.keyAttribute,
                type: item.type,
                required: item.required === true || item.required === 'true',
                referenceable: !!Object.is(item.referenceable, true),
              })
            }
          })
        }
      }
    })

    list.map((item) => {
      // đưa nội dung ở phần tử level3 vào
      index = data.findIndex((x) => Object.is(x.keyAttribute, item.keyAttribute))
      if (index !== -1) {
        if (list[index].metadata) {
          list[index].metadata.map((itemL) => {
            indexChild = data[index].metadata.findIndex((x) => Object.is(x.keyAttribute, itemL.keyAttribute))
            if (indexChild !== -1 && data[index].metadata[indexChild].metadata) {
              list[index].metadata[indexChild].metadata.map((item) => {
                data[index].metadata[indexChild].metadata.push({
                  keyAttribute: item.keyAttribute,
                  type: item.type,
                  referenceable: !!Object.is(item.referenceable, true),
                  required: item.required === true || item.required === 'true',
                  // minSegmentSize: Object.is(item.minSegmentSize, 'none') ? null : item.minSegmentSize,
                })
              })
            }
          })
        }
      }
    })
    return data
  },
  handleGetNameApp(list, nameEdit) {
    const arr = []
    if (nameEdit) {
      const data = list.filter((x) => x.title !== nameEdit)
      if (data) {
        data.map((item) => {
          arr.push(item.title)
        })
      }
    } else {
      list.map((item) => {
        arr.push(item.title)
      })
    }
    return arr
  },
  // get style Array Object Name(title) && id apps
  listNameApp(id, listDataApp) {
    const arr = []
    const data = listDataApp.filter((x) => x.id !== id)
    data.map((item) => {
      arr.push(item)
    })
    return arr
  },
  handleConvertAppReferenceApp(listAppC, listDataApp) {
    const arr = []
    listAppC.map((item) => {
      const data = listDataApp.find((x) => x.title === item)
      if (data) {
        arr.push(data)
      }
    })
    return arr
  },
  convertDataClient(list = [], isClone = false) {
    const data = []; var index; var indexChild
    list.map((item) => {
      if (Object.is(item.type.toLowerCase(), 'object') || Object.is(item.type.toLowerCase(), 'array')) {
        data.push({
          view: !!Object.is(item.view, 'true'),
          keyAttribute: item.keyAttribute,
          type: item.type,
          required: item.required === true || item.required === 'true',
          isExpanded: false,
          isAdd: isClone,
          referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
          referenceToFieldValue: '',
          metadata: [],
        })
      } else if (typeRef.includes(item.type.toLowerCase())) {
        let referenceToFieldValue = '';
        if (item.referenceToApp) {
          referenceToFieldValue = item.referenceToField ? `${item.referenceToApp}|${item.referenceToField}` : item.referenceToApp;
        }
        data.push({
          view: !!Object.is(item.view, 'true'),
          keyAttribute: item.keyAttribute,
          type: item.type,
          required: item.required === true || item.required === 'true',
          isExpanded: false,
          isAdd: isClone,
          referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
          referenceToFieldValue,
        })
      } else {
        data.push({
          view: !!Object.is(item.view, 'true'),
          keyAttribute: item.keyAttribute,
          type: item.type,
          required: item.required === true || item.required === 'true',
          isExpanded: false,
          isAdd: isClone,
          referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
          referenceToFieldValue: '',
        })
      }
    })

    list.map((item) => {
      // đưa nội dung phần tử 2 vào
      index = data.findIndex((x) => Object.is(x.keyAttribute, item.keyAttribute))
      if (index !== -1) {
        if (list[index].metadata) {
          list[index].metadata.map((item) => {
            if (Object.is(item.type.toLowerCase(), 'object') || Object.is(item.type.toLowerCase(), 'array')) {
              data[index].metadata.push({
                keyAttribute: item.keyAttribute,
                type: item.type,
                required: item.required === true || item.required === 'true',
                isExpanded: false,
                isAdd: isClone,
                referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
                referenceToFieldValue: '',
                metadata: [],
              })
            } else if (typeRef.includes(item.type.toLowerCase())) {
              let referenceToFieldValue = '';
              if (item.referenceToApp) {
                referenceToFieldValue = item.referenceToField ? `${item.referenceToApp}|${item.referenceToField}` : item.referenceToApp;
              }
              data[index].metadata.push({
                keyAttribute: item.keyAttribute,
                type: item.type,
                required: item.required === true || item.required === 'true',
                isExpanded: false,
                isAdd: isClone,
                referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
                referenceToFieldValue,
              })
            } else {
              data[index].metadata.push({
                keyAttribute: item.keyAttribute,
                type: item.type,
                required: item.required === true || item.required === 'true',
                isExpanded: false,
                isAdd: isClone,
                referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
                referenceToFieldValue: '',
              })
            }
          })
        }
      }
    })

    list.map((item) => {
      // đưa nội dung ở phần tử level3 vào
      index = data.findIndex((x) => Object.is(x.keyAttribute, item.keyAttribute))
      if (index !== -1) {
        if (list[index].metadata) {
          list[index].metadata.map((itemL) => {
            indexChild = data[index].metadata.findIndex((x) => Object.is(x.keyAttribute, itemL.keyAttribute))
            if (indexChild !== -1 && data[index].metadata[indexChild].metadata) {
              list[index].metadata[indexChild].metadata.map((item) => {
                data[index].metadata[indexChild].metadata.push({
                  keyAttribute: item.keyAttribute,
                  type: item.type,
                  required: item.required === true || item.required === 'true',
                  isExpanded: false,
                  isAdd: isClone,
                  referenceable: Object.is(item.referenceable, undefined) ? '' : item.referenceable,
                  referenceToFieldValue: '',
                })
              })
            }
          })
        }
      }
    })
    return data
  },
  titleRules: [
    (v) => !!v || 'Title is required',
    (v) => (v && v.length <= 255) || 'Max 255 characters',
  ],
  descriptionRules: [
    (v) => !!v || 'Description is required',
    (v) => (v && v.length <= 10000) || 'Max 255 characters',
  ],
  typeAppRules: [
    (v) => !!v || 'Type App is required',
    (v) => (v && v !== null) || 'Choose the type of app',
  ],
  imageRules: [
    // (v) => !!v || 'Image is required',
    // (v) => (v && v.length <= 9999) || 'Max 1000 characters',
    // (v) => (v && !!(new RegExp('^(https?:\\/\\/)?' + // protocol
    // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    // '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    // '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    // '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    // '(\\#[-a-z\\d_]*)?$', 'i')).test(v)) || 'Image must be a link',
    value => !value || value.size < 1024 * 1024 || 'Logo size should be less than 1 MB!',
  ],
  typeApp: [{ text: 'Protected', value: 'protected' }, { text: 'Private', value: 'private' }, { text: 'Public', value: 'public' }],
}
