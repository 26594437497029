/**
 * Vue i18n
 *
 * @library
 *
 *
 */

// Lib imports
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import TokenService from '../helpers/token'

import { authLocale } from '@/modules/auth/locale'
import { userLocale } from '@/modules/admin/user/locale'
import { appsLocale } from '@/modules/admin/app/locale'
import { aliasLocale } from '@/modules/admin/alias/locale'
import { rolesLocale } from '@/modules/admin/role/locale'
import { settingsLocale } from '@/modules/admin/settings/locale'
import { storageDriveLocale } from '@/modules/admin/storageDrive/locale'
import { groupLocale } from '@/modules/admin/group/locale'
import { packageMediaLocale } from '@/modules/admin/packageMedia/locale'
import { versionLocale } from '@/modules/admin/version/locale'

import { layoutLocale } from '@/layouts/locale'
export const defaultLocale = 'vi'

Vue.use(VueI18n)
const locale = TokenService.getLang() || defaultLocale

const i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
        vi: {
            auth: authLocale.vi,
            user: userLocale.vi,
            apps: appsLocale.vi,
            alias: aliasLocale.vi,
            roles: rolesLocale.vi,
            settings: settingsLocale.vi,
            storageDrive: storageDriveLocale.vi,
            group: groupLocale.vi,
            layout: layoutLocale.vi,
            packageMedia: packageMediaLocale.vi,
            version: versionLocale.vi,
        },
        en: {
            auth: authLocale.en,
            user: userLocale.en,
            apps: appsLocale.en,
            alias: aliasLocale.en,
            roles: rolesLocale.en,
            settings: settingsLocale.en,
            storageDrive: storageDriveLocale.en,
            group: groupLocale.en,
            layout: layoutLocale.en,
            packageMedia: packageMediaLocale.en,
            version: versionLocale.en,
        },
    },
})

export default i18n
