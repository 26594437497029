export const rolesForm = {
    breadcrumb: {
        titleRolesAdd: 'Add Roles',
        titleAliasDetail: 'Detail ALIAS',
        titleRolesUpdate: 'Update Roles',
    },
    form: {
        roles: 'Roles',
        status: 'Status',
        level: 'Level',
    },
    actions: {
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
    },
}
