export const signUp = {
    signUp: 'Đăng ký',
    firstName: 'Tên',
    lastName: 'Họ',
    email: 'E-mail',
    phone: 'Điện thoại',
    password: 'Mật khẩu',
    confirmPassword: 'Nhập lại mật khẩu',
    signIn: 'Đăng nhập',
}
