import Repository from './Repository'
const resource = 'roles'

export default {
    getDataRoleAll (tokenLogin) {
        return Repository.get(`${resource}/all`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    getDataRoles (tokenLogin) {
        return Repository.get(`${resource}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    createRole (dataBody, tokenLogin) {
        return Repository.post(`${resource}`, dataBody, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    updateRole (id, dataBody, tokenLogin) {
        return Repository.put(`${resource}/${id}`, dataBody, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    deleteRole (id, tokenLogin) {
        return Repository.delete(`${resource}/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
}
