export const appsList = {
    breadcrumb: {
        title: 'Quản lý App',
    },
    filter: {
        title: 'Tên',
        titleOrId: 'Tên hoặc Id',
        selectStatus: 'Chọn trạng thái',
        selectType: 'Chọn loại',
        selectGroup: 'Chọn nhóm',
        selectOwnerGroup: 'Chọn tác giả',
    },
    table: {
        name: 'Tên',
        description: 'Mô tả',
        type: 'Loại',
        status: 'Trạng thái',
        referenceFromApps: 'Các ứng dụng tham khảo',
        actions: 'Hành động',
    },
    actions: {
        add: 'Thêm',
        view: 'Xem',
        edit: 'Sửa',
        delete: 'Xoá',
        cancel: 'Huỷ bỏ',
        import: 'Import',
    },
    confirmed: {
        titleDelete: 'Xác nhận xóa Item',
        textDelete: 'Bạn có chắc bạn muốn xóa mục này?',
    },
}
