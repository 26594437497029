<template>
  <v-app>
    <!-- Show loading when call API -->
    <progress-circular />
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-app>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass'
  import ProgressCircular from './modules/layout/components/ProgressCircular'
  // import Vuex
  import { sync, get } from 'vuex-pathify'

  export default {
    name: 'App',
    components: {
      ProgressCircular,
    },
    computed: {
      ...sync('app', [
        'items',
      ]),
      ...get('adminApp', [
        'listAllDataApp',
      ]),
      ...get('userProfile', [
        'dataProfile',
      ]),
    },
    watch: {
      dataProfile: {
        handler (newDataProfile, oldVal) {
          if (!Array.isArray(newDataProfile?.dataGroups) || !newDataProfile?.dataGroups.length || this.isAdminLogin()) {
            return;
          }
          const selectedGroupId = localStorage.getItem('groupId') || '-1'
          const foundDataGroup = newDataProfile.dataGroups.find(dataGroup => dataGroup.id === selectedGroupId) || newDataProfile.dataGroups[0];
          if (foundDataGroup?.isManagerUser) {
            this.items.push({
              title: this.$t('layout.sidebar.users'),
              icon: 'mdi-account-group',
              to: '/admin-user/',
            })
          }
          if (this.isGroupAdminLogin()) {
            this.items.push(...[
              {
                title: this.$t('layout.sidebar.group'),
                icon: 'mdi-group',
                to: '/admin-group/',
              },
              {
                title: this.$t('layout.sidebar.apps'),
                icon: 'mdi-apps',
                to: '/admin-app/',
              },
            ])
          }
        },
        deep: true,
        immediate: true,
      },
    },
    async created () {
      // Menu Item
      if (this.isAdminLogin()) {
        const data = JSON.parse(localStorage.getItem('dataUserLogin'))
        if (Object.is(data.role, 'Admin')) {
          this.items = [
            {
              title: this.$t('layout.sidebar.dashboard'),
              icon: 'mdi-view-dashboard',
              to: '/',
            },
            {
              title: this.$t('layout.sidebar.users'),
              icon: 'mdi-account-group',
              to: '/admin-user/',
            },
            {
              title: this.$t('layout.sidebar.group'),
              icon: 'mdi-group',
              to: '/admin-group/',
            },
            {
              title: this.$t('layout.sidebar.manageApps'),
              icon: 'mdi-account-cog',
              to: '/manage-apps/',
            },
            {
              title: this.$t('layout.sidebar.apps'),
              icon: 'mdi-apps',
              to: '/admin-app/',
            },
            {
              title: this.$t('layout.sidebar.schedule'),
              icon: 'mdi-calendar',
              to: '/schedule/',
            },
            {
              title: this.$t('layout.sidebar.notifications'),
              icon: 'mdi-bell',
              to: '/admin-notifications/',
            },
            {
              title: this.$t('layout.sidebar.settings'),
              icon: 'mdi-cog',
              to: '/setting/',
            },
          ]
        } else {
          this.items = [
            {
              title: this.$t('layout.sidebar.dashboard'),
              icon: 'mdi-view-dashboard',
              to: '/',
            },
            {
              title: this.$t('layout.sidebar.users'),
              icon: 'mdi-account-group',
              to: '/admin-user/',
            },
            {
              title: this.$t('layout.sidebar.group'),
              icon: 'mdi-group',
              to: '/admin-group/',
            },
            {
              title: this.$t('layout.sidebar.manageMedia'),
              icon: 'mdi-file',
              to: '/manage-media/',
            },
            {
              title: this.$t('layout.sidebar.manageApps'),
              icon: 'mdi-account-cog',
              to: '/manage-apps/',
            },
            {
              title: this.$t('layout.sidebar.apps'),
              icon: 'mdi-apps',
              to: '/admin-app/',
            },
            {
              title: this.$t('layout.sidebar.notifications'),
              icon: 'mdi-bell',
              to: '/admin-notifications/',
            },
            {
              title: this.$t('layout.sidebar.settings'),
              icon: 'mdi-cog',
              to: '/setting/',
            },
            {
              title: this.$t('layout.sidebar.roles'),
              icon: 'mdi-account-key',
              to: '/admin-role/',
            },
            {
              title: this.$t('layout.sidebar.version'),
              icon: 'mdi-git',
              to: '/admin-version/',
            },
            {
              title: this.$t('layout.sidebar.packageMedia'),
              icon: 'mdi-package',
              to: '/admin-package-media/',
            },
            {
              title: this.$t('layout.sidebar.storageDrive'),
              icon: 'mdi-package-down',
              to: '/admin-storage-drive/',
            },
            {
              title: this.$t('layout.sidebar.schedule'),
              icon: 'mdi-calendar',
              to: '/schedule/',
            },
          ]
        }
      } else {
        this.items = [
          {
            title: this.$t('layout.sidebar.dashboard'),
            icon: 'mdi-view-dashboard',
            to: '/',
          },
          {
            title: this.$t('layout.sidebar.manageMedia'),
            icon: 'mdi-file',
            to: '/manage-media/',
          },
          {
            title: this.$t('layout.sidebar.manageApps'),
            icon: 'mdi-account-cog',
            to: '/manage-apps/',
          },
          {
            title: this.$t('layout.sidebar.schedule'),
            icon: 'mdi-calendar',
            to: '/schedule/',
          },
          // {
          //   title: 'Profile',
          //   icon: 'mdi-account',
          //   to: '/profile/',
          // },
          // {
          //   title: 'User Profile',
          //   icon: 'mdi-account',
          //   to: '/components/profile/',
          // },
          // {
          //   title: 'Regular Tables',
          //   icon: 'mdi-clipboard-outline',
          //   to: '/tables/regular/',
          // },
          // {
          //   title: 'Typography',
          //   icon: 'mdi-format-font',
          //   to: '/components/typography/',
          // },
          // {
          //   title: 'Icons',
          //   icon: 'mdi-chart-bubble',
          //   to: '/components/icons/',
          // },
          // {
          //   title: 'Google Maps',
          //   icon: 'mdi-map-marker',
          //   to: '/maps/google/',
          // },
          // {
          //   title: this.$t('layout.sidebar.notifications'),
          //   icon: 'mdi-bell',
          //   to: '/components/notifications/',
          // },
        ]
      }
    },
    // metaInfo: {
    //   title: 'App',
    //   titleTemplate: [],
    //   htmlAttrs: { lang: 'en' },
    //   meta: [
    //     { charset: 'utf-8' },
    //     { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    //   ],
    // },
  }
</script>
<style>
*[lazy=loading] {
  background-size: 40px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  transition: none !important;
  height: 40px !important;
  width: 40px !important;
}
</style>