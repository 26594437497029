export const userList = {
    breadcrumb: {
        title: 'User Managers',
    },
    filter: {
        selectApp: 'Select App',
        selectRoles: 'Select Roles',
        selectStatus: 'Select Status',
        selectGroup: 'Select Group',
    },
    table: {
        email: 'Email',
        name: 'Name',
        roles: 'Roles',
        status: 'Status',
        actions: 'Actions',
    },
    actions: {
        add: 'Add',
        addChild: 'Add Child',
        deleteInReference: 'Delete in reference',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
    },
}
