import Repository from './Repository'
const resource = 'group'

export default {
  createGroup(dataBody, tokenLogin) {
    return Repository.post(`${resource}`, dataBody, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  getDataGroups(params, tokenLogin) {
    return Repository.post(`${resource}/list`, {
      params,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  updateGroup(id, dataBody, tokenLogin) {
    return Repository.post(`${resource}/update/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: dataBody,
    })
  },
  deleteGroup(id, tokenLogin) {
    return Repository.post(`${resource}/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  resetSecretKeyGroup (id, tokenLogin) {
    return Repository.post(`${resource}/update/${id}/resetsecretkey`, {
        headers: {
            Authorization: `Bearer ${tokenLogin}`,
        },
    })
  },
}
