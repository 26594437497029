export const userList = {
    breadcrumb: {
        title: 'Quản lý người dùng',
    },
    filter: {
        selectApp: 'Chọn app',
        selectRoles: 'Chọn quyền',
        selectStatus: 'Chọn trạng thái',
        selectGroup: 'Chọn nhóm',
    },
    table: {
        email: 'Email',
        name: 'Họ tên',
        roles: 'Quyền',
        status: 'Trạng thái',
        actions: 'Hành động',
    },
    actions: {
        add: 'Thêm',
        addChild: 'Thêm data con',
        deleteInReference: 'Xoá ở tham khảo',
        view: 'Xem',
        edit: 'Sửa',
        delete: 'Xoá',
    },
}
