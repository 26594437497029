import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL // Incase of /api/v1;
// const baseURL = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_VERSIONS_BASE_URL}`
const instance = axios.create({
  baseURL,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Origin: 'https://admin.techupcorp.com',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    // withCredentials: false,
    // mode: 'no-cors',
  },
  // withCredentials: true,
  validateStatus: function (status) {
    return status >= 200 && status < 550 // trả về true hay null hay undefined thì sẽ xử lý, không thì sẽ từ chối
  },
})

instance.interceptors.request.use(async (response) => {
  return response
}, async (error) => {
  return error
})

export default instance
