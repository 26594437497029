export const aliasForm = {
    breadcrumb: {
        titleAliasAdd: 'Add ALIAS',
        titleAliasDetail: 'Detail ALIAS',
        titleAliasUpdate: 'Update ALIAS',
    },
    form: {
        title: 'Title',
        description: 'Description',
        status: 'Status',
        query: 'Query',
        addQuery: 'Add Query',
    },
    actions: {
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
    },
}
