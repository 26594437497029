import Repository from './Repository'
const resource = 'version'

export default {
  createVersion(dataBody, tokenLogin) {
    return Repository.post(`${resource}/create`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: dataBody,
    })
  },
  getDataVersions(params, tokenLogin) {
    return Repository.post(`${resource}/list`, {
      params,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  updateVersion(id, dataBody, tokenLogin) {
    return Repository.post(`${resource}/update/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      data: dataBody,
    })
  },
  deleteVersion(id, tokenLogin) {
    return Repository.post(`${resource}/detete/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
}
