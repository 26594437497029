export const settingsList = {
    breadcrumb: {
        title: 'Cài đặt App',
    },
    version: {
        title: 'Thông tin phiên bản',
        name: 'Tên App:',
        date: 'Ngày phát hành:',
    },
    api: {
        title: 'Thông tin API',
    },
    email: {
        title: 'Thông tin Email Root',
        port: 'Cổng',
        userName: 'Tên người dùng',
        password: 'Mật khẩu',
    },
    minio: {
        title: 'Thông tin Minio',
        key: 'Key',
        secret: 'Secret',
        region: 'Region',
        endpoint: 'Endpoint',
        prefixBucket: 'Prefix Bucket',
    },
    password: {
        title: 'Đăng nhập mật khẩu',
        button: 'Đổi mật khẩu',
        form: {
            title: 'Đổi mật khẩu mới',
            currentPassword: 'Mật khẩu hiện tại',
            retypeCurrentPassword: 'Nhập lại mật khẩu hiện tại',
            newPassword: 'Mật khẩu mới',
            retypeNewPassword: 'Nhập lại mật khẩu mới',
        },
    },
    authentication: {
        title: 'Cài đặt xác thực',
    },
    emailConfiguration: {
        title: 'Cấu hình Email',
        titleTestEmail: 'Kiểm tra cấu hình Email',
    },
    minioSetting: {
        title: 'Cấu hình Minio',
        titleTestMinio: 'Kiểm tra cấu hình Minio',
    },
    generalSettings: {
        title: 'Cài đặt chung',
    },
    actions: {
        close: 'Đóng',
        cancel: 'Huỷ',
        save: 'Lưu',
        edit: 'Sửa',
        delete: 'Xoá',
        reset: 'Cài lại',
        testEmail: 'Kiểm tra cấu hình Email',
        testMinio: 'Kiểm tra cấu hình Minio',
        send: 'Gửi',
    },
}
