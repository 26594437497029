// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const UserProfileRepository = Repository.get('userProfile')

const state = {
    dataProfile: {
        email: '',
        fullName: '',
        role: '',
        dataGroups: [],
    },
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    // getters,
    mutations: {
        ...mutations,
    },
    actions: {
        // export data user profile
        async fetchDataUserProfile (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const userProfileRepository = await UserProfileRepository.getDataMe(tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataUserRepository = userProfileRepository.data
            return dataUserRepository
            // context.commit('dataProfile', dataUserRepository.data)
        },
    },
    // mutations,
    // ,
}
