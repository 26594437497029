import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { Plugin } from 'vue-fragment'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import VueLazyload from 'vue-lazyload'
const loadimage = require('./assets/images/loading.gif')
const errorimage = require('./assets/images/image-not-found.jpg')


// plugins
import i18n from './plugins/i18n'

// mixins
import miXin from './mixins/index'

Vue.config.productionTip = false

sync(store, router)

Vue.mixin(miXin)
Vue.use(VueToast, {
  position: 'top',
  duration: 4000,
})

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

Vue.use(Plugin)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})

Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: errorimage,
	loading: loadimage,
	attempt: 1,
	listenEvents: [ 'scroll' ],
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  },
  lazyComponent: true,
  filter: {
    lazyComponent ({ el, src }) {
      if (el.classList.contains('not-lazy-load')) {
        el.setAttribute('src', src);
        el.loaded = true;
      }
    },
  },
  adapter: {
    loaded ({ el }) {
      // Save the original transition
      const originalTransition = el.style.transition;

      // Set the transition to none
      el.style.transition = 'none';

      // Revert the transition back after 0.05 second
      setTimeout(() => {
        el.style.transition = originalTransition;
      }, 50);
    },
  },
})

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
