// Utilities
import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
const AdminUserRepository = Repository.get('adminUser')

const state = {
    // component HeaderForm
    isShowDialogUserForm: false,
    // component Table
    isShowDialogUserFormDetail: false,
    isShowDialogUserFormEdit: false,
    isShowDialogUserFormDelete: false,
    // component Table and FilterForm
    dataUsers: [],
    dataUsersAppAdmin: [],
    totalUser: 0,
    dataOwnerGroup: [],
    totalOwnerGroup: 0,
    params: {
        offset: 1,
        limit: 10,
        email: '',
        block: '',
        role: '',
        appId: '',
        groupId: '',
    },
    // component DialogUserFormAdd
    dataForm: {
        id: '',
        fullName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        role: '',
        block: true,
    },
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    getters: {
        getIsShowDialogUserForm: state => {
            return state
        },
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async fetchAllDataUserByRole (context, { tokenLogin = '', role = '', isAllGroup = false }) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const params = {
                offset: 1,
                limit: 1000,
                role,
            }
            // call API get data users
            const adminUserRepository = await AdminUserRepository.getDataUsers(params, tokenLogin, isAllGroup)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserResult = adminUserRepository.data
            return dataAdminUserResult?.data?.list || []
        },
        // component Table and FilterForm
        async fetchDataUserList (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const params = context.state.params
            // call API get data users
            const adminUserRepository = await AdminUserRepository.getDataUsers(params, tokenLogin, true)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserResult = adminUserRepository.data
            context.commit('dataUsers', dataAdminUserResult.data.list)
            context.commit('totalUser', dataAdminUserResult.data.total)
        },
        // call API get data user have role App Admin
        async fetchDataUserAppAdminList (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const params = context.state.params
            // call API get data users
            const adminUserRepository = await AdminUserRepository.getDataUsers(params, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserResult = adminUserRepository.data
            context.commit('dataOwnerGroup', dataAdminUserResult.data.list)
            context.commit('totalOwnerGroup', dataAdminUserResult.data.total)
        },
        async fetchDataOwnerGroupByUser (context, tokenLogin) {
          context.commit('layout/isShowProgressCircular', true, { root: true })
          const adminUserRepository = await AdminUserRepository.getDataOwnerGroupByUser(tokenLogin)
          context.commit('layout/isShowProgressCircular', false, { root: true })
          const dataAdminUserResult = adminUserRepository.data
          context.commit('dataUsersAppAdmin', dataAdminUserResult.data.list)
          context.commit('totalUser', dataAdminUserResult.data.total)
          return dataAdminUserResult
        },
        setDefaultFormDataUser (context) {
            context.commit('dataForm', {
                id: '',
                fullName: '',
                email: '',
                password: '',
                passwordConfirm: '',
                role: '',
            })
        },
        // component DialogUserFormAdd
        async createUser (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const dataBody = context.state.dataForm
            // call API post user
            const adminUserRepository = await AdminUserRepository.createUser(dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserResult = adminUserRepository.data
            return dataAdminUserResult
        },
        // component Table and DialogUserFormEdit
        async detailUser (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            // call API users detail
            const adminUserRepository = await AdminUserRepository.getUsersDetail(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserDetail = adminUserRepository.data
            context.commit('dataForm', dataAdminUserDetail.data)
        },
        // component DialogUserFormEdit
        async updateUser (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            const dataBody = context.state.dataForm
            // call API update User
            const adminUserRepository = await AdminUserRepository.updateUsers(id, dataBody, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserDetail = adminUserRepository.data
            return dataAdminUserDetail
        },
        // use component DialogUserFormDelete
        async blockUser (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            // call API block User
            const adminUserRepository = await AdminUserRepository.blockUsers(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserDetail = adminUserRepository.data
            return dataAdminUserDetail
        },
        async deleteUser (context, tokenLogin) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const id = context.state.dataForm.id
            // call API delete User
            const adminUserRepository = await AdminUserRepository.deleteUsers(id, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataAdminUserDetail = adminUserRepository.data
            return dataAdminUserDetail
        },
        async joinGroup (context, { tokenLogin, dataForm }) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const adminUserRepository = await AdminUserRepository.joinGroup(dataForm, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataResult = adminUserRepository.data
            return dataResult
        },

        async changeUserStatus (context, { tokenLogin, dataForm }) {
            // Show Loading
            context.commit('layout/isShowProgressCircular', true, { root: true })
            const adminUserRepository = await AdminUserRepository.changeUserStatus(dataForm, tokenLogin)
            context.commit('layout/isShowProgressCircular', false, { root: true })
            const dataResult = adminUserRepository.data
            return dataResult
        },

    },
    // mutations,
    // ,
}
