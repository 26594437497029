import { userList as userListVi } from './vi/userList.locale'
import { userList as userListEn } from './en/userList.locale'
//
import { userForm as userFormVi } from './vi/userForm.locale'
import { userForm as userFormEn } from './en/userForm.locale'
export const userLocale = {
    vi: {
        userList: userListVi,
        userForm: userFormVi,
    },
    en: {
        userList: userListEn,
        userForm: userFormEn,
    },
}
