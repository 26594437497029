export const appsForm = {
    breadcrumb: {
        titleAppAdd: 'Thêm APP',
        titleAppDetail: 'Chi tiết APP',
        titleAppUpdate: 'Cập nhật APP',
        titleAppConfirm: 'Xác nhận',
    },
    form: {
        group: 'Nhóm',
        title: 'Tên',
        description: 'Mô tả',
        roles: 'Quyền',
        status: 'Trạng thái',
        message: 'Bạn có muốn xóa vĩnh viễn hay chỉ khóa app?',
    },
    actions: {
        cancel: 'Huỷ',
        close: 'Đóng',
        save: 'Lưu',
        edit: 'Sửa',
        block: 'Khoá',
        notBlock: 'Bỏ khoá',
        delete: 'Xoá',
    },
    notifications: {
        appsAdd: {
            success: 'Thêm App thành công',
            error: 'Title app đã tồn tại',
        },
        appsEdit: {
            success: 'Cập nhật App thành công',
            error: 'Title app đã tồn tại',
            confirm: 'Bạn chắc chắn muốn xóa!',
        },
        appsDelete: {
            success: 'Xóa app thành công',
            lock: 'Khoá app thành công',
        },
    },
}
