import { aliasList as aliasListVi } from './vi/aliasList.locale'
import { aliasList as aliasListEn } from './en/aliasList.locale'
//
import { aliasForm as aliasFormVi } from './vi/aliasForm.locale'
import { aliasForm as aliasFormEn } from './en/aliasForm.locale'
export const aliasLocale = {
    vi: {
        aliasList: aliasListVi,
        aliasForm: aliasFormVi,
    },
    en: {
        aliasList: aliasListEn,
        aliasForm: aliasFormEn,
    },
}
