export const login = {
    login: 'Đăng nhập',
    email: 'E-mail',
    phone: 'Điện thoại',
    password: 'Mật khẩu',
    forgotPassword: 'Quên mật khẩu?',
    signUp: 'Đăng Ký',
    signUpUsing: 'Hoặc đăng ký bằng',
    notifications: {
        success: 'Đăng nhập thành công',
        error: {
          user: 'Email hoặc Phone không đúng định dạng',
          emailAndPassword: 'Sai email hoặc mật khẩu',
          '001.001.114': 'Email Or Phone không đúng định dạng',
          '002.001.003': 'Sai email hoặc mật khẩu',
        },
    },
}
