export const aliasList = {
    breadcrumb: {
        title: 'Danh sách Alias',
    },
    table: {
        title: 'Tên',
        description: 'Mô tả',
        status: 'Trạng thái',
        actions: 'Hành động',
    },
    actions: {
        create: 'Tạo alias',
        view: 'Xem',
        edit: 'Sửa',
        delete: 'Xoá',
    },
}
