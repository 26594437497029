export const settingsList = {
    breadcrumb: {
        title: 'App Settings',
    },
    version: {
        title: 'Version Information',
        name: 'App Name:',
        date: 'Release Date:',
    },
    api: {
        title: 'API Information',
    },
    email: {
        title: 'Email Information',
        port: 'Port',
        userName: 'User Name',
        password: 'Password',
    },
    minio: {
        title: 'Minio Information',
        key: 'Key',
        secret: 'Secret',
        region: 'Region',
        endpoint: 'Endpoint',
        prefixBucket: 'Prefix Bucket',
    },
    password: {
        title: 'Login Password',
        button: 'Change Password',
        form: {
            title: 'Change new password',
            currentPassword: 'Current password',
            retypeCurrentPassword: 'Retype Current password',
            newPassword: 'New password',
            retypeNewPassword: 'Retype New Password',
        },
    },
    authentication: {
        title: 'Authentication Settings',
    },
    emailConfiguration: {
        title: 'Email Configuration',
        titleTestEmail: 'Test Email Configuration',
    },
    minioSetting: {
        title: 'Minio Setting',
        titleTestMinio: 'Test Minio Setting',
    },
    generalSettings: {
        title: 'General Settings',
    },
    actions: {
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        edit: 'Edit',
        delete: 'Delete',
        testEmail: 'Test Email',
        testMinio: 'Test Minio',
        reset: 'Reset',
    },
}
