export const aliasList = {
    breadcrumb: {
        title: 'Alias List',
    },
    table: {
        title: 'Title',
        description: 'Description',
        status: 'Status',
        actions: 'Actions',
    },
    actions: {
        create: 'Create alias',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
    },
}
