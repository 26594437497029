import Repository from './Repository'
const resource = 'alias'
export default {
    getAliasList (params, tokenLogin) {
        return Repository.post(`${resource}/list`, {
            params,
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    createAlias (dataBody, tokenLogin) {
        return Repository.post(`${resource}/create`, {
            data: dataBody,
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    updateAlias (id, dataBody, tokenLogin) {
        return Repository.post(`${resource}/update/${id}`, {
            data: dataBody,
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    deleteAlias (id, tokenLogin) {
        return Repository.post(`${resource}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
}
