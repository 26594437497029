export const versionList = {
    page: {

    },
    filter: {

    },
    table: {

    },
}
