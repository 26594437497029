import Repository from './Repository'
const resource = 'apps'
export default {
    getDataApps (params, tokenLogin) {
        const selectedGroupId = localStorage.getItem('groupId')
        return Repository.post(`${resource}/list`, {
            params: {
                ...params,
                ...selectedGroupId ? {
                    groupId: selectedGroupId,
                } : {},
            },
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    getDataAppsofUserLogin(params = {}, tokenLogin, isAllGroup = false, groupId = '') {
        const selectedGroupId = groupId || localStorage.getItem('groupId')
        return Repository.post(`${resource}/get-all`, {
            params,
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
                ...selectedGroupId && !isAllGroup ? {
                    group_id: selectedGroupId,
                } : {},
            },
        })
    },
    getDataAppsNotGroup(tokenLogin) {
        return Repository.post(`${resource}/get-all-not-group`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    getAppsDetail (id, tokenLogin) {
        return Repository.post(`${resource}/detail/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    createApp (dataBody, tokenLogin) {
        return Repository.post(`${resource}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
            data: dataBody,
        })
    },
    updateApp (id, dataBody, tokenLogin) {
        return Repository.post(`${resource}/update/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
            data: dataBody,
        })
    },
    lockUpApp (id, tokenLogin) {
        return Repository.post(`${resource}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    deleteApp (id, tokenLogin) {
        return Repository.post(`${resource}/delete/${id}?isHardDelete=true`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
            },
        })
    },
    resetSecretKey (id, valueSecretKey, tokenLogin) {
        return Repository.post(`${resource}/update/${id}/resetsecretkey`, {
            headers: {
                Authorization: `Bearer ${tokenLogin}`,
                secretkey: valueSecretKey,
            },
        })
    },
}
