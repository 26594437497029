export const login = {
    login: 'Login',
    email: 'Email',
    phone: 'Phone',
    emailOrPhone: 'Email Or Phone',
    password: 'Password',
    forgotPassword: 'Forgot password?',
    signUp: 'Sign Up',
    signUpUsing: 'Or sign up using',
    notifications: {
        success: 'Logged in successfully',
        error: {
          user: 'Email Or Phone is not in the correct format',
          emailAndPassword: 'Wrong email or password',
          '001.001.114': 'Email Or Phone is not in the correct format',
          '002.001.003': 'Wrong email or password',
        },
    },
}
