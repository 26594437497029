export const packageMediaForm = {
    breadcrumb: {

    },
    form: {
      unit: 'Unit',

    },
    actions: {

    },
    notifications: {
        error: {
          '001.001.001': 'This attribute cannot be left blank',
          '001.001.013': 'This attribute is greater than the maximum value',
          '001.001.012': 'This attribute is less than the minimum value, min: 1',
        },
    },
}
