import Repository from './Repository'
const resource = 'schedule'

export default {
  createSchedule(dataBody, tokenLogin) {
    return Repository.post(`${resource}`, dataBody, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  getDataSchedules(params, tokenLogin) {
    const selectedGroupId = localStorage.getItem('groupId')
    return Repository.post(`${resource}/list`, {
      params: {
        ...params,
        ...selectedGroupId ? {
            groupId: selectedGroupId,
        } : {},
      },
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    })
  },
  async downloadFile(scheduleId, tokenLogin) {
    return Repository.post(`${resource}/download`, {
      params: {
        id: scheduleId,
      },
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
      // responseType: 'type',
      // responseType: 'blob',
    }, {
      responseType: 'blob',
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${scheduleId}.zip`);
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
  },
  importFile(formData, tokenLogin) {
    return Repository.post(`${resource}/import`, formData, {
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
