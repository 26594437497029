export const storageDriveForm = {
    breadcrumb: {

    },
    form: {
        titleForm: 'Storage Drive',
        title: 'Title',
        key: 'Key',
        secret: 'Secret',
        region: 'Region',
        prefixBucket: 'Prefix Bucket',
        endpoint: 'Endpoint',
        actions: 'Actions',
    },
    actions: {
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        edit: 'Edit',
        create: 'Add',
    },
}
