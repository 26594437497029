export const packageMediaForm = {
    breadcrumb: {

    },
    form: {
      unit: 'Đơn vị',
    },
    actions: {
        close: 'Đóng',
        save: 'Lưu',
        edit: 'Sửa',
        block: 'Khoá',
        notBlock: 'Bỏ khoá',
        delete: 'Xoá',
    },
    notifications: {
        error: {
          '001.001.001': 'Thuộc tính này không được bỏ trống',
          '001.001.013': 'Thuộc tính này lớn hơn giá trị tối đa',
          '001.001.012': 'Thuộc tính này nhỏ hơn giá trị tối thiểu", nhỏ nhất: 1',
        },
    },
}
