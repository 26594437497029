export const storageDriveList = {
  page: {
    title: 'Quản Lý Vùng Lưu Trữ',
  },
  table: {
    title: 'Tiêu đề',
    key: 'Key',
    secret: 'Secret',
    region: 'Region',
    prefixBucket: 'Prefix Bucket',
    endpoint: 'Endpoint',
    updatedAt: 'Updated At',
    actions: 'Hành động',
  },
}
