import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'
// import { capitalize } from 'lodash'
const AdminAppRepository = Repository.get('adminApp')
const AccessAppRepository = Repository.get('accessApp')
const MediaRepository = Repository.get('media')

const state = {
  dataChildApp: '',
  viewChildAppId: '',
  appChildItemId: '',
  dataChildAppsById: {},
  dataSeletectedChildApp: {},
  isAddChildMetaData: false,
  itemChildData: {},
  rowItemChild: {},
  dataMetaItemDelete: {},
  refChildApp: {},

  dataSeletectedApp: {},
  viewAppId: '',
  appItemId: '',
  params: {
    offset: 1,
    limit: 10,
    orderAttribute: '',
    order: '',
    userId: '',
  },
  totalItem: 0,
  dataItems: [],
  showTable: false,
  isShowDialogItemFormUpdate: false,
  itemId: '',
  dataItem: {},
  query: {},
  isShowDialogImportFileMedia: false,
  keyAttributeFileMedia: '',
  fileTypeMedia: '',
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    ...mutations,
  },
  actions: {
    async getDataApps(context, { tokenLogin, isShowPublic, isAllGroup = false }) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API get data apps all
      const adminAppRepository = await AdminAppRepository.getDataAppsofUserLogin({ isShowPublic }, tokenLogin, isAllGroup)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      const dataAdminAppResult = adminAppRepository.data
      if (!dataAdminAppResult.data) {
        dataAdminAppResult.data = {
          list: [],
          total: 0,
        }
      }
      return dataAdminAppResult.data.list
    },
    async detailApp (context, { tokenLogin, id }) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API app detail
      const adminAppRepository = await AdminAppRepository.getAppsDetail(id, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      context.commit('dataSeletectedApp', adminAppRepository.data.data || {})
    },
    async detailChildApp (context, { tokenLogin, id }) {
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API app detail
      const adminAppRepository = await AdminAppRepository.getAppsDetail(id, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      context.commit('dataSeletectedChildApp', adminAppRepository.data.data || {})
    },
    async getAllItemMainApp (context, tokenLogin) {
      const params = {
        limit: 500,
      }
      const dataSeletectedApp = context.state.dataSeletectedApp
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.getDataListApp(params, {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data.data.list
    },
    async getItemApp (context, { tokenLogin, params, filter = {} }) {
      const { dataSeletectedApp = {}, viewAppId = '', appItemId = '' } = context.state
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: appItemId,
        app_child: viewAppId,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }

      if (!viewAppId || (viewAppId === dataSeletectedApp.id)) {
        delete headers.app_child
        delete headers.app_item_id
      }

      // eslint-disable-next-line prefer-const
      let queryFilter = {}
      // eslint-disable-next-line prefer-const
      let isHaveQueryFilter = false

      function formatFilterQuery(filter, queryFilter = {}) {
        const { children = [], logicalOperator = '' } = filter
        if (logicalOperator && children.length) {
          queryFilter.condition = logicalOperator === 'all' ? 'and' : 'or'
          queryFilter.query = []
          children.forEach(dataFilter => {
            //  operator = ''
            const { value = '', children = [], rule = '' } = dataFilter.query;
            // eslint-disable-next-line prefer-const
            let dataQuery = {}
            if (children.length) {
              formatFilterQuery(dataFilter.query, dataQuery)
              if (dataQuery.query.length) {
                queryFilter.query.push(dataQuery)
              }
            } else if (value && rule) {
              dataQuery[rule] = value
              isHaveQueryFilter = true
              queryFilter.query.push({ query: dataQuery })

            }
          })
        }
      }

      formatFilterQuery(filter, queryFilter, isHaveQueryFilter)
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.getDataListApp(params, headers, tokenLogin, isHaveQueryFilter ? queryFilter : {})
      context.commit('layout/isShowProgressCircular', false, { root: true })
      context.commit('totalItem', resultAPI.data.data.total || 0)
      context.commit('dataItems', resultAPI.data.data.list || [])

      // console.log('[resultAPI]', resultAPI);
      // console.log(params, headers, tokenLogin, queryFilter);
      return resultAPI.data.data
    },
    async deleteReferenceChildItem (context, { tokenLogin, itemId }) {
      const { dataChildApp = {}, appChildItemId = {}, dataSeletectedChildApp = {}, refChildApp = {}, dataSeletectedApp = {}, viewChildAppId = '' } = context.state
      const headersReference = {
        app_id: dataSeletectedChildApp.id,
        secretkey: dataSeletectedChildApp.secretkey,
        app_item_id: appChildItemId,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: dataChildApp.id,
        app_child: viewChildAppId,
        app_child_id: refChildApp.id,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // Delete data in app child
      await AccessAppRepository.deleteItem(headersReference, tokenLogin)
      // Remove the id of data from the array ( update array )
      const resultAPI = await AccessAppRepository.deleteItem(headers, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async deleteReferenceChildItemArrayRef (context, { tokenLogin, itemId }) {
      const { dataItem = {}, appChildItemId = {}, dataSeletectedApp = {}, dataSeletectedChildApp = {}, viewChildAppId = '' } = context.state
      const headersReference = {
        app_id: dataSeletectedChildApp.id,
        secretkey: dataSeletectedChildApp.secretkey,
        app_item_id: appChildItemId,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }

      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: viewChildAppId,
        group_id: dataSeletectedApp.dataGroup.id,
        group_secretkey: dataSeletectedApp.dataGroup.secretkey,
      }
      delete dataItem.id
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // Delete data in app child
      await AccessAppRepository.deleteItem(headersReference, tokenLogin)
      // Remove the id of data from the array ( update array )
      const resultAPI = await AccessAppRepository.updateItem(dataItem, headers, tokenLogin)
      context.state.viewChildAppId = ''
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async deleteChildItem (context, { tokenLogin, itemId }) {
      const { appChildItemId = {}, refChildApp = {}, dataSeletectedApp = {}, viewChildAppId = '' } = context.state
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: appChildItemId,
        app_child: viewChildAppId,
        app_child_id: refChildApp.id,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.deleteItem(headers, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async deleteItem (context, { tokenLogin, itemId }) {
      const { dataSeletectedApp = {}, viewAppId = '', appItemId = '' } = context.state
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: appItemId,
        app_child: viewAppId,
        app_child_id: itemId,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }

      if (!viewAppId || (viewAppId === dataSeletectedApp.id)) {
        delete headers.app_child
        delete headers.app_child_id
        headers.app_item_id = itemId
      }
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.deleteItem(headers, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async updateItem (context, tokenLogin) {
      const { dataSeletectedApp = {}, viewAppId = '', appItemId = '', itemId = '', dataItem = {} } = context.state
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: appItemId,
        app_child: viewAppId,
        app_child_id: itemId,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }

      if (!viewAppId || (viewAppId === dataSeletectedApp.id)) {
        delete headers.app_child
        delete headers.app_child_id
        headers.app_item_id = itemId
      }
      const metadataTypeArrayRef = []
      await dataSeletectedApp.metadata.forEach(item => {
        if (item.type === 'array') {
          metadataTypeArrayRef.push(item.keyAttribute)
        }
      })
      await metadataTypeArrayRef.forEach(item => {
        delete dataItem[item]
      })
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.updateItem(dataItem, headers, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async updateChildItemArrayRef (context, tokenLogin) {
      const { itemId = '', dataSeletectedApp = {}, dataItem = {} } = context.state
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: itemId,
        group_id: dataSeletectedApp.dataGroup.id,
        group_secretkey: dataSeletectedApp.dataGroup.secretkey,
      }
      delete dataItem.id
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.updateItem(dataItem, headers, tokenLogin)
      context.state.dataItem = {}
      context.state.itemId = ''
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async updateChildItem (context, tokenLogin) {
      const { itemId = '', dataSeletectedChildApp = {}, dataItem = {} } = context.state
      const headers = {
        app_id: dataSeletectedChildApp.id,
        secretkey: dataSeletectedChildApp.secretkey,
        app_item_id: itemId,
        group_id: dataSeletectedChildApp?.dataGroup?.id,
        group_secretkey: dataSeletectedChildApp?.dataGroup?.secretkey,
      }
      delete dataItem.id

      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.updateItem(dataItem, headers, tokenLogin)

      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async createChildItem (context, tokenLogin) {
      const { rowItemChild = {}, itemChildData = {}, appChildItemId = '', dataSeletectedChildApp = {}, dataSeletectedApp = {}, dataItem = {} } = context.state
      const headers = {
        app_id: dataSeletectedChildApp.id,
        secretkey: dataSeletectedChildApp.secretkey,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }
      // Add data to child app
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.createItem(dataItem, headers, tokenLogin)

      context.commit('layout/isShowProgressCircular', false, { root: true })

      const findAppChild = await dataSeletectedApp.referenceableToFieldApps.find(res => res.keyAttribute === itemChildData.keyAttribute);
      const findMetaDataChild = await dataSeletectedApp.metadata.find(res => res.keyAttribute === itemChildData.keyAttribute)
      const headersParents = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: appChildItemId,
        app_child: findAppChild.parentKeyAttribute,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }
      // If the type is refs_array, the newly created data will be updated into the refs array
      if (findMetaDataChild && findMetaDataChild.type === 'refs_array') {
        const bodyParentsUpdate = rowItemChild[findMetaDataChild.keyAttribute]
        bodyParentsUpdate.unshift(resultAPI.data.data.id)
        const dataBody = {}
        dataBody[findMetaDataChild.keyAttribute] = bodyParentsUpdate
        // delete headersParents.app_child
        context.commit('layout/isShowProgressCircular', true, { root: true })

        const resultParentsUpdateAPI = await AccessAppRepository.updateItem(dataBody, headersParents, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        context.state.appChildItemId = ''
        return resultParentsUpdateAPI.data
      } else {
        // In the remaining cases, add the data just created in the child app to the parent app's Reference feild
        const bodyParentsUpdate = {}
        bodyParentsUpdate[findAppChild.keyAttribute] = resultAPI.data.data.id;
        context.commit('layout/isShowProgressCircular', true, { root: true })
        const resultParentsUpdateAPI = await AccessAppRepository.createItem(bodyParentsUpdate, headersParents, tokenLogin)
        context.commit('layout/isShowProgressCircular', false, { root: true })
        return resultParentsUpdateAPI.data
      }
    },
    async createItem (context, tokenLogin) {
      const { dataSeletectedApp = {}, viewAppId = '', appItemId = '', dataItem = {} } = context.state
      const headers = {
        app_id: dataSeletectedApp.id,
        secretkey: dataSeletectedApp.secretkey,
        app_item_id: appItemId,
        app_child: viewAppId,
        group_id: dataSeletectedApp?.dataGroup?.id,
        group_secretkey: dataSeletectedApp?.dataGroup?.secretkey,
      }

      if (!viewAppId || (viewAppId === dataSeletectedApp.id)) {
        delete headers.app_child
        delete headers.app_item_id
      }
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await AccessAppRepository.createItem(dataItem, headers, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async uploadFile(context, data) {
      const { dataSeletectedApp = {} } = context.state;
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await MediaRepository.uploadFile(data.formData, dataSeletectedApp.id, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async getFiles(context, tokenLogin) {
      const { dataSeletectedApp = {} } = context.state
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await MediaRepository.getFiles(dataSeletectedApp.id, '', tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
  },
}
