// Vuex
import { sync } from 'vuex-pathify'
import { ROLES, FLAG_LOGIN } from '../constant'

export default {
  computed: {
    ...sync('layout', [
      'isShowProgressCircular',
    ]),
  },
  methods: {
    getPageTotal (itemTotal, limit = 10) {
      return Math.ceil(itemTotal / limit)
    },
    upperFristString (i, twodot = ':') {
      return i.charAt(0).toUpperCase() + i.slice(1) + twodot
    },
    isLogin () {
      // return function when loggedIn === "OK"
      return localStorage.getItem('loggedIn') === FLAG_LOGIN
    },
    getDataLogin () {
      try {
        // get value from localStorage.setItem('dataUserLogin') component LoginForm
        const dataUserLogin = localStorage.getItem('dataUserLogin')
        // Convert from style String change into style Object then return function
        return JSON.parse(dataUserLogin)
        } catch (error) {
        return {}
      }
    },
    getUserIdLogin () {
      // get value type Object from function getDataLogin()
      const dataUserLogin = this.getDataLogin() || {}
      return dataUserLogin.id
    },
    getEmailLogin () {
      // get value style Object from function getDataLogin()
      const dataUserLogin = this.getDataLogin() || {}
      // get value email from object const dataUserLogin then return function getEmailLogin()
      return dataUserLogin.email
    },
    getTokenLogin () {
      // get value style Object from function getDataLogin()
      const dataUserLogin = this.getDataLogin() || {}
      // get value token from object const dataUserLogin then return function getTokenLogin()
      return dataUserLogin.token
    },
    getRoleLogin () {
      // get value style Object from function getDataLogin()
      const dataUserLogin = this.getDataLogin() || {}
      // get value role from object const dataUserLogin then return function getRoleLogin()
      return dataUserLogin.role
    },
    isAdminLogin () {
      const roleLogin = this.getRoleLogin()
      return roleLogin === ROLES.ADMIN || roleLogin === ROLES.ROOT
    },
    isRoleUserLogin () {
      const roleLogin = this.getRoleLogin()
      return roleLogin === ROLES.USER
    },
    isGroupAdminLogin () {
      const roleLogin = this.getRoleLogin()
      return roleLogin === ROLES.GROUP_ADMIN
    },
    // show loading (ProgressCircular)
    showProgressCircular () {
      this.isShowProgressCircular = true
    },
    hideProgressCircular () {
      this.isShowProgressCircular = false
    },
    getAppsOfUser (dataApps = []) {
      return dataApps
    },
  },
}
