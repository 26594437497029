export const aliasForm = {
    breadcrumb: {
        titleAliasAdd: 'Thêm ALIAS',
        titleAliasDetail: 'Chi tiết ALIAS',
        titleAliasUpdate: 'Cập nhật ALIAS',
    },
    form: {
        title: 'Tên',
        description: 'Mô tả',
        status: 'Trạng thái',
        query: 'Truy vấn',
        addQuery: 'Thêm Truy Vấn',
        keyQuery: 'Tên Khoá Truy Vấn',
    },
    actions: {
        close: 'Đóng',
        cancel: 'Huỷ',
        save: 'Lưu',
    },
}
