import { make } from 'vuex-pathify'
import Repository from '@/repository/RepositoryFactory'

const MediaRepository = Repository.get('media')

const state = {
  selectedAppId: '',
  userId: '',
  files: [],
  filterFileType: '',
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    ...mutations,
  },
  actions: {
    async getFiles(context, tokenLogin) {
      const { selectedAppId = '', userId = '' } = context.state
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await MediaRepository.getFiles(selectedAppId, userId, tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async uploadFile(context, data) {
      const { selectedAppId = '' } = context.state
      context.commit('layout/isShowProgressCircular', true, { root: true })
      const resultAPI = await MediaRepository.uploadFile(data.formData, selectedAppId, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return resultAPI.data
    },
    async deleteFile(context, data) {
      const { selectedAppId = '' } = context.state
      // Show Loading
      context.commit('layout/isShowProgressCircular', true, { root: true })
      // call API delete
      const adminStorageDriveRepository = await MediaRepository.deleteFile(selectedAppId, data.fileName, data.tokenLogin)
      context.commit('layout/isShowProgressCircular', false, { root: true })
      return adminStorageDriveRepository
    },
  },
}
