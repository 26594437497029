export const userForm = {
    breadcrumb: {
        titleUserAdd: 'Thêm người dùng',
        titleUserDetail: 'Chi tiết người dùng',
        titleUserUpdate: 'Cập nhật người dùng',
        titleUserConfirm: 'Xác nhận',
    },
    form: {
        email: 'E-mail',
        name: 'Tên',
        password: 'Mật khẩu',
        confirmPassword: 'Nhập lại mật khẩu',
        roles: 'Quyền',
        status: 'Trạng thái',
        actions: 'Hành động',
        message: 'Bạn có muốn xóa vĩnh viễn hay chỉ khóa user?',
    },
    actions: {
        close: 'Đóng',
        save: 'Lưu',
        saveAddChild: 'Lưu thêm data con',
        updateChildItem: 'Cập nhật item con',
        saveAndMore: 'Lưu và thêm',
        edit: 'Sửa',
        block: 'Khoá',
        notBlock: 'Bỏ khoá',
        delete: 'Xoá',
    },
    notifications: {
        userAdd: {
            success: 'Thêm tài khoản thành công',
        },
        userEdit: {
            success: 'Cập nhật người dùng thành công',
        },
        userDelete: {
            success: 'Xóa người dùng thành công',
            lock: 'Khóa người dùng thành công',
            unlock: 'Bỏ khóa người dùng thành công',
        },
        error: {
          '001.001.011': 'Mật khẩu của bạn phải có từ 8 - 20 kí tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt (! @ # $, V.v.), nhưng không được chứa dấu cách',
          '001.001.004': 'Email không đúng định dạng',
        },
    },
}
